import React, { useRef, useEffect } from 'react';
import '../css/Main.css';
import '../css/Elements.css'
import '../css/Projects.css';
import Button from './Button';

function ProjectComponentB({compBTitle, compBDetail, logo, bgColor, textColor, translate, scale, borderColor, marginTop, animate, align, EntireBoxMTop, Textbottom, textMargin}) {
  return (
        <div className="componentB" style={{backgroundColor:bgColor, color:textColor, marginTop:EntireBoxMTop}}>
            <img src={logo} id="c-b-img" alt="" style={{border: borderColor+'20px solid', borderRadius:'0.8em', background: bgColor, transform:translate, scale:scale, marginTop:marginTop, animation:animate}}/>
            <div className="c-b-text">
               <div className="c-b-wraper" style={{position:'absolute', bottom:Textbottom, textAlign:align, margin:textMargin}}>
                  <h2>{compBTitle}</h2>
                  <p>{compBDetail}</p>
               </div>
            </div>
        </div>
  )
}

export default ProjectComponentB