import React, { useRef, useEffect } from 'react';
import Navbar from './Navbar';
import '../css/Main.css';
import '../css/Elements.css'
import HeroImage from './HeroImage';

const HeroBanner = ({projectTitle, textBlockMarginTop, projectHeadline, bannerImage, bannerWidth,bannerImgMargin, borderR, HeroBGcolor, bannerChip1, bannerChip2, bannerChip3, bannerChip4, bgColor,color,tagBG,tagCl, spinner, HeroMargin, HeroEdge}) => {

  return (
    <body id="banner" style={{backgroundImage:bgColor}}>
      <div className="top-container">
        <Navbar />
        <div className="hero-banner-container" id="hero-top">
            <div className="hero-banner-wrapper">
              <div className="text-wrapper-project" style={{marginTop:textBlockMarginTop}}>
                <img src={bannerImage} alt="brann_avartar" id="banner-product_mb"/>
                <h1 className="project-header-title">{projectTitle}</h1>
                <h3 className="project-header-headline">{projectHeadline}</h3>
                <div className="bannerTags">
                  <div className="bannerTag" style={{backgroundColor:tagBG, color:tagCl}}>{bannerChip1}</div>
                  <div className="bannerTag" style={{backgroundColor:tagBG, color:tagCl}}>{bannerChip2}</div>
                </div>
                <div className="bannerTags" style={{marginTop:'-30px'}}>
                  <div className="bannerTag" style={{backgroundColor:tagBG, color:tagCl}}>{bannerChip3}</div>
                  <div className="bannerTag" style={{backgroundColor:tagBG, color:tagCl}}>{bannerChip4}</div>
                </div>
              </div>
            </div>
            <HeroImage 
            bannerImage = {bannerImage}
            borderR = {borderR}
            HeroBGcolor = {HeroBGcolor}
            spinner={spinner}
            HeroMargin={HeroMargin}
            HeroEdge={HeroEdge}
            bannerWidth={bannerWidth}
            bannerImgMargin={bannerImgMargin}
            />
        </div>  
      </div>
  </body>
  )
}

export default HeroBanner