import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { scrollThere } from './functions/scrollThere';
// CSS
import './css/Main.css'
import './css/Projects.css'
import './css/Research.css'
import './css/Elements.css'
// COMPO
import HeroBanner from './elements/HeroBanner'
import Breadcrumb from './elements/Breadcrumb';
import ProjectBrief from './elements/ProjectBrief';
import ProjectComponentA from './elements/ProjectComponentA';
import ProjectComponentB from './elements/ProjectComponentB';
import ProjectComponentC from './elements/ProjectComponentC';
import ProjectComponentC2 from './elements/ProjectComponentC2';
import Project from './elements/ProjectComponentC';
import ProjectComponentE from './elements/ProjectComponentE';
import ReserchBlockA from './elements/ReserchBlockA';
import ReserchBlockB from './elements/ReserchBlockB';
import ReserchBlockC from './elements/ReserchBlockC';
import ReserchBlockD from './elements/ReserchBlockD';
import Footer     from './elements/Footer';
import ProjectNavigator from './elements/ProjectNavigator';
import CardMobile from './elements/CardMobile';
// IMG
import rs_banner from './img/homepage/projectRacingSusan/rs_banner.png'
import rs_mockup_imac from './img/homepage/projectRacingSusan/rs_mockup_imac.png'
import rs_widget1 from './img/homepage/projectRacingSusan/rs_helps.png'
import rs_widget2 from './img/homepage/projectRacingSusan/rs_analyse.png'
import rs_widget3 from './img/homepage/projectRacingSusan/rs_export.png'
import rs_login from './img/homepage/projectRacingSusan/rs_login.png'
import rs_landing from './img/homepage/projectRacingSusan/rs_landing.png'
import rs_loans from './img/homepage/projectRacingSusan/rs_credit.png'
import rs_set1 from './img/homepage/projectRacingSusan/rs_p1.png'
import rs_set2 from './img/homepage/projectRacingSusan/rs_credit.png'
import rs_set3 from './img/homepage/projectRacingSusan/rs_login.png'


function ProjectRacingSusan() {

    // linear-gradient(82deg, rgba(255,255,255,1) 24%, rgba(86,134,214,1) 63%)

  window.scrollTo(0,0);
  return (
    <>
    <div className="desktop">
      <div id="project-top">
        <HeroBanner 
        projectTitle="Racing Susan"
        textBlockMarginTop="120px"
        projectHeadline="Get rid of small businesses finance troubles. Keep them on track, seek the best options of B2B suppliers, and grow their credit strongly."
        bannerImage={rs_banner}
        bannerWidth="160%"
        HeroBGcolor="linear-gradient(333deg, rgba(232,0,93,1) 0%, rgba(255,165,146,1) 100%)"
        borderR="50em 0 0 50em"
        bannerChip1="Product Design and Research"
        bannerChip2="6 Weeks"
        bannerChip3="UX Designer and researcher"
        bannerChip4="Desktop"
        bgColor="white"
        color="rgba(255,255,255,0.8)"
        tagBG="#E8005D"
        tagCl="white"
        spinner="none"
        />
      </div>
      <div className="breadcrumb-ctrl" >
        <Breadcrumb 
          top="project-top"
          section1="Introduction"
          section2="Product"
          section3="Prototype"
          section4="Research"
          section1Lable="Introduction"
          section2Lable="Product"
          section3Lable="Prototype"
          section4Lable="Research"
          currentPage="Racing Susan"
        />
      </div>
      <div className="stories">
        <div className="story" id="Introduction">
          <ProjectBrief 
          challenges="Small businesses often struggle to manage their finances. Limited staffing and financial literacy challenges can prevent them from using professional tools, concerning their complexity and time-consuming processes. Racing Susan offers an intuitive SaaS solution to counter these issues."
          roles="I manage an end-to-end process from a design perspective. Including ideation, user research, and UX design. The major focus is learning users' challenges that prevent them from adopting new tools available in the market. By uncovering this, I find out the fundamental points they needed."
          tools="Figma | Google Suites | Miro | Paper and Pen | Whiteboard"
          />
        </div>
        <div className="story" id="Product">
          <ProjectComponentA 
            bgIMG={rs_mockup_imac}
            bgColor="#be1a50"
            compATitle="Easy across platforms"
            compADetail="No more formatting cumbersome!"
            textMargin="0 0 0 -50px"
            CompAPadding="40px"
          />
          <div className="cos-com-b" style={{marginTop:'200px'}}>
                <ProjectComponentB 
                  logo={rs_widget1}
                  compBTitle="Late Fees?"
                  compBDetail="No more..."
                  bgColor="rgb(237, 237, 237)"
                  textColor="#494949"
                  translate="translateX(-25%)"
                  align="center"
                  textMargin="10px 10px 10px 1.9%"
                  marginTop="-180px"
                />
                <ProjectComponentB 
                  logo={rs_widget2}
                  compBTitle="Customized"
                  compBDetail="your visuals"
                  bgColor="rgb(237, 237, 237)"
                  textColor="#494949"
                  align="center"
                  textMargin="10px 10px 10px 0.3%"
                  marginTop="-100px"
                />
                <ProjectComponentB 
                  logo={rs_widget3}
                  compBTitle="Formats?"
                  compBDetail="Not an issue!"
                  bgColor="rgb(237, 237, 237)"
                  textColor="#494949"
                  translate="translateX(25%)"
                  align="center"
                  textMargin="10px 10px 10px 2.4%"
                  marginTop="-100px"
                />
            </div>
            <ProjectComponentC 
                logo={rs_login}
                compBTitle="A team player?"
                compBDetail="Assign your finance workload to your team with different accessibilities. Fix issues faster With asynchronous methods!"
                BGRoundColor="#E8005D"
                IMGScale="1.2"
                compCTextColor="#E8005D"
            />
            <ProjectComponentC2
                logo={rs_landing}
                compBTitle="On Point & On Track"
                compBDetail="Keep you notified of your financial health easily from our dashboard systems. Systematically allow you to self-evaluate each task with extra AI suggestions on how to improve them."
                BGRoundColor=""
                IMGScale="1.2"
                IMGMargin="0 0 0 80px"
                CompCAnimation="none"
                compCTextColor="#E8005D"
            />
            <ProjectComponentC 
                compCMargin="-40px 0 0 0"
                logo={rs_loans}
                compBTitle="Credit & Partners"
                compBDetail="With more credit to increase your cash flow over 60%, good partners can make it happen!"
                BGRoundColor=""
                IMGScale="1.2"
                compCTextColor="#E8005D"
                CompCAnimation="none"
                compCTextMargin="140px auto auto auto"
            />
        </div>
        <div className="story" id="Prototype" style={{marginTop:'240px'}}>
            {/* <div className="desktop-prototype">
              <div className="componentE">
                  <div className="c-e-prototype-desktop">
                      <div className="v-text-container">
                          <h2 id="proto-v-text" style={{color:""}}>Prototype</h2>
                      </div>
                      <iframe id="prototype-desktop"src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FdJOOR6CIG7Jpxiv4D1r0Sl%2FUntitled%3Fpage-id%3D0%253A1%26node-id%3D101-446%26viewport%3D541%252C37%252C0.11%26t%3DudwY77qqIuS2LTXt-1%26scaling%3Dcontain%26content-scaling%3Dfixed%26starting-point-node-id%3D101%253A446%26show-proto-sidebar%3D1%26hide-ui%3D1" allowfullscreen></iframe>
                  </div>    
              </div>
            </div> */}
            <div className="desktop-prototype">
                {/* <div className="v-text-container">
                  <h2 id="proto-v-text" style={{color:"#e0bdb8"}}>Prototype</h2>
                </div> */}
                <iframe id="prototype-desktop"src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FdJOOR6CIG7Jpxiv4D1r0Sl%2FUntitled%3Fpage-id%3D0%253A1%26node-id%3D101-446%26viewport%3D541%252C37%252C0.11%26t%3DudwY77qqIuS2LTXt-1%26scaling%3Dcontain%26content-scaling%3Dfixed%26starting-point-node-id%3D101%253A446%26show-proto-sidebar%3D1%26hide-ui%3D1" style={{borderRadius:'1.8em', maxWidth:'900px'}} allowfullscreen></iframe>
            </div>
        </div>
        <div className="story" id="Research">
            <div className="v-text-container">
                <h2 id="proto-v-text" style={{color:'#999999', marginTop:'-80px'}}>UX Research</h2>
            </div>
            <div className="ux-research">

            <div className="ux-r-intro">
                    <p>To come up with solutions, we progressed through iterations of analysis which can be divided into 3 steps. Ideas and opinions, Interpretation, and Integration.</p>
                </div>

                <ReserchBlockB 
                BlockTitle1="Market Research"
                BlockTitle2="Interviews"
                BlockTitle3="Card Sorting"
                BlockDetail1="In a market where everything is almost already existing, I analyze successful features and functions, market responses, and feedback to identify opportunities and what gaps need to be filled for users."
                BlockDetail2="With feedback from 10 businesses, focusing on small business owners who take care of general and financial management, to identify missing links and what they truly require. This process was crucial in helping me understand key areas to improve and focus on."
                BlockDetail3="Every business and individual handles management differently. Card-sorting these key functions from the interview helps me better understand how they organize information. This insight leads to intuitive grouping and information architecture that is sensible to end-users."
                /> 

              <div className="amigo-finding">
                <h4>Findings</h4>
                <div id="amigo-finding-no">
                  <h4 style={{backgroundColor:'#E8005D'}}>1</h4>
                  <p> Many businesses <b style={{color:'#E8005D'}}>misunderstood that improving their organization's financial and digital literacy will be difficult and time-consuming </b>. This is why they avoid adopting new products that exist in the market. </p>
                </div>
                <div id="amigo-finding-no">
                  <h4 style={{backgroundColor:'#E8005D'}}>2</h4>
                  <p>It is important to  <b style={{color:'#E8005D'}}> keep users notified on the latest update </b> to leave an adequate time for planning and effective execution. </p>
                </div>
                <div id="amigo-finding-no">
                  <h4 style={{backgroundColor:'#E8005D'}}>3</h4>
                  <p>As the functions become more complex and technical, <b style={{color:'#E8005D'}}>we need to make the product as simple and intuitive as much as possible </b> regardless of of how integrated these functions to uses' daily life.</p>
                </div>
                <div className="talk" style={{marginTop:'80px'}}>
                  <p style={{ fontSize:'24px' ,color:'rgb(36,36,36)', fontWeight:'400', width:'1160px', textAlign:'left', margin:'auto', padding:'24px', borderRadius:'0.5em', lineHeight:'50px'}}>More key insights and what I learn from Racing Susan? <br/> Let's schedule a meeting and talk about it!   
                  <a href="mailto:bannawit@umich.edu"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480l0-83.6c0-4 1.5-7.8 4.2-10.8L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z"/></svg></a>
                  </p>
                  {/* Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. */}
                </div>
              </div>
            </div>
        </div>
  
        <div className="story" style={{padding:'80px 138px 40px 138px'}}> 
          <ProjectNavigator 
            BlockTitle1="Cos"
            BlockDetail1="FinTech "
            to1="/cos-finance"
            BlockTitle2="AmíGo"
            BlockDetail2="Travel "
            to2="/amigo"
            BlockTitle3="Racing Susan"
            BlockDetail3="FinTech "
            to3="/racing-susan"
            />
          <Footer />
        </div>
      </div>
    </div>
    <div className="mobile">
        <CardMobile 
          projectName="Racing Susan"
          projectImg={rs_banner}
          projectImgWidth="80%"
          projectImgBGColor="#E8005D"
          projectDescribtion="Get rid of small businesses' finance troubles. Keep them on track, seek the best options of B2B suppliers, and grow their credit strongly."
          bannerChip1="Product Design"
          bannerChip2="6 Weeks"
          bannerChip3="UX Designer and researcher"
          bannerChip4="Desktop"
          challenges="Small businesses often struggle to manage their finances. Limited staffing and financial literacy challenges can prevent them from using professional tools, concerning their complexity and time-consuming processes. Racing Susan offers an intuitive SaaS solution to counter these issues."
          roles="I manage an end-to-end process from a design perspective. Including ideation, user research, and UX design. The major focus is learning users' challenges that prevent them from adopting new tools available in the market. By uncovering this, I find out the fundamental points they needed."
          tools="Figma | Google Suites | Miro | Paper and Pen | Whiteboard"
          bgIMG={rs_mockup_imac}
          bgColor="#be1a50"
          compATitle="Easy across platforms"
          compADetail="No more formatting cumbersome!"
          textMargin="0 0 0 -50px"
          imgSet1={rs_set1}
          imgSet2={rs_set2}
          imgSet3={rs_set3}
          set1Head="Be on point and on the track!"
          set1Detail="Oh no, cash flow got under 60% of the near future expenses! You've got this! Racing Susan keep you notified of your financial health easily from our dashboard systems. Systematically allow you to self-evaluate each task with extra AI suggestions on how to improve them."
          set2Head="Control your credit and get to know new partners!"
          set2Detail="With more credit to increase your cash flow over 60%, good partners can make it happen, to boost and keep your businesses thrive in this slow market season!"
          set3Head="Too heavy to contribute alone?"
          set3Detail="You can now assign your finance workload to your team with different accessibility to your credential information. With asynchronous methods, you can now communicate within the system and fix issues faster!"
          destination="https://www.figma.com/proto/dJOOR6CIG7Jpxiv4D1r0Sl/Untitled?page-id=0%3A1&node-id=101-446&viewport=541%2C37%2C0.11&t=udwY77qqIuS2LTXt-1&scaling=contain&content-scaling=fixed&starting-point-node-id=101%3A446&show-proto-sidebar=1"
          bgcolor="rgb(0,106,255)"
          holder="Prototype"
        />
    </div>
    </>
  )
}

export default ProjectRacingSusan