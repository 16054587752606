import React, { useRef, useEffect } from 'react';
import '../css/Main.css';
import '../css/Elements.css'
import '../css/Projects.css';

const ProjectBrief = ({challenges, roles, tools}) => {
    const elementRefL = useRef(null);
  
    useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY >= 80) {
          elementRefL.current.style.left = '0';
        } else {
          elementRefL.current.style.left = '-100%'; 
        }
      };
  
      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

return (
    <>
    <div className="project-brief-container" id="breif-top" ref={elementRefL}>
        <h2>Challenges</h2>
        <p>{challenges}</p>
        <h2>What I did</h2>
        <p>{roles}</p>
        <h2>Tools</h2>
        <p>{tools}</p>
    </div>
    </>
 );
};
export default ProjectBrief


{/* <div className="project-brief-container" id="breif-top">
<h2>Challenges</h2>
<p>Complications and inconsistencies slow young professionals down from completing their financial tasks quickly. By leveraging design systems and thoughtful information architecture, we can help them achieve their goal efficiently and satisfactorily.</p>
<h2>What I did</h2>
<p>Identified users’ issues and needs, analyzed and brought solutions to designs, and developed prototypes for clear communication.</p>
<h2>Tools</h2>
<p>Figma | Google Suites | Miro | Adobe Illustrator | Adobe Photoshop | Sketchbook and Pen</p>
</div> */}