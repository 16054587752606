import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { scrollThere } from './functions/scrollThere';
// CSS
import './css/Main.css'
import './css/Projects.css'
import './css/Research.css'
import './css/Elements.css'
// COMPO
import HeroBanner from './elements/HeroBanner'
import Breadcrumb from './elements/Breadcrumb';
import ProjectBrief from './elements/ProjectBrief';
import ProjectComponentA from './elements/ProjectComponentA';
import ProjectComponentB from './elements/ProjectComponentB';
import ProjectComponentC from './elements/ProjectComponentC';
import ProjectComponentC2 from './elements/ProjectComponentC2';
import Project from './elements/ProjectComponentC';
import ProjectComponentE from './elements/ProjectComponentE';
import ReserchBlockA from './elements/ReserchBlockA';
import ReserchBlockB from './elements/ReserchBlockB';
import ReserchBlockC from './elements/ReserchBlockC';
import ReserchBlockD from './elements/ReserchBlockD';
import Footer     from './elements/Footer';
import ProjectNavigator from './elements/ProjectNavigator';
import CardMobile from './elements/CardMobile';
// IMG
import kt_banner from './img/homepage/projectKitchr/kt_banner.png'
import kt_stories from './img/homepage/projectKitchr/kt_stories.png'
import kt_ads from './img/homepage/projectKitchr/kt_ads.png'
import kt_set1 from './img/homepage/projectKitchr/kt_group1.png'
import kt_set2 from './img/homepage/projectKitchr/kt_group2.png'
import kt_set3 from './img/homepage/projectKitchr/kt_group3.png'
import kt_storyBoard from './img/homepage/projectKitchr/kt_storyBoard.png'
import kt_persona from './img/homepage/projectKitchr/kt_persona.png'
import kt_alt from './img/homepage/projectKitchr/kt_alt.png'
import kt_flow1 from './img/homepage/projectKitchr/kt_flow1.png'
import kt_qoc1 from './img/homepage/projectKitchr/kt_qoc1.png'
import kt_qoc2 from './img/homepage/projectKitchr/kt_qoc2.png'
import kt_qoc3 from './img/homepage/projectKitchr/kt_qoc3.png'
import kt_wireframe from './img/homepage/projectKitchr/kt_wireframe.png'

function ProjectKitchr() {

    // linear-gradient(82deg, rgba(255,255,255,1) 24%, rgba(86,134,214,1) 63%)

  window.scrollTo(0,0);
  return (
    <>
    <div className="desktop">
      <div id="project-top">
        <HeroBanner 
        projectTitle="Kitchr"
        textBlockMarginTop="120px"
        projectHeadline="Kitchr bridges the gap between food communities in towns, connecting people and food suppliers closer for authenticity and variety of choices!"
        bannerImage={kt_banner}
        bannerWidth="160%"
        HeroBGcolor="#A7F8DA"
        borderR="50em 0 0 50em"
        bannerChip1="Product Design"
        bannerChip2="14 Weeks"
        bannerChip3="Design and Research Lead"
        bannerChip4="Mobile"
        bgColor="white"
        color="rgba(255,255,255,0.8)"
        tagBG="#1E805E"
        tagCl="white"
        spinner="none"
        />
      </div>
      <Breadcrumb 
        top="project-top"
        section1="Introduction"
        section2="Product"
        section3="Prototype"
        section4="Research"
        section1Lable="Introduction"
        section2Lable="Product"
        section3Lable="Prototype"
        section4Lable="Research"
        currentPage="Kitchr"
      />
      <div className="stories">
        <div className="story" id="Introduction">
          <ProjectBrief 
          challenges="The problem is the lack of affordable food supplies where wasted food remains an issue. This is where Kitchr comes in to help bridge these gaps with solutions for people to meet up and start sharing."
          roles="We started with prototypes targeting university-age users, and then scaling up to larger cities and towns with similarity in the scarce variety for food options."
          tools="Figma | Google Suites | Miro | Paper and Pen | Illustrator" 
          />
        </div>
        <div className="story" id="Product">
          <img src={kt_stories} alt="" style={{width:"100%", margin:'80px 0 80px 0'}}/>
          <ProjectComponentA 
            bgIMG={kt_ads}
            bgColor="#E7FFF8"
            compATitle="Do grocery shopping and cook locally"
            CompAImgWidth="30%"
            CompAImgMargin="0 40px 0 160px"
            compADetail="Build a stronger food community."
            textMargin="0 0 0 0"
            CompAPadding="40px"
            blockATextColor="#494949"
          />

            <ProjectComponentC 
                logo={kt_set1}
                compBTitle="Save more with options"
                compBDetail="Save up to $700 annually! Turning grocery shopping into a mindful activity, where you can buy, share, and cook meals with your local friends."
                BGRoundColor="#1E805E"
                IMGScale="1.2"
                compCTextColor="#1E805E"
            />
            <ProjectComponentC2
                logo={kt_set2}
                compBTitle="Connected & Safe"
                compBDetail="We checked and double-checked to make sure your local is safe! We would like everyone to enjoy food, cooking, and shopping together happily!"
                BGRoundColor=""
                IMGScale="1.2"
                IMGMargin="0 0 0 80px"
                CompCAnimation="none"
                compCTextColor="#1E805E"
                compCTextMargin="80px auto auto auto"
            />
            <ProjectComponentC 
                compCMargin="-40px 0 0 0"
                logo={kt_set3}
                compBTitle="Show the world your kitchen?"
                compBDetail="Be a big person and invite a friend! Host a cooking event, and make the community your favorite dish!"
                BGRoundColor=""
                IMGScale="1.2"
                compCTextColor="#1E805E"
                CompCAnimation="none"
                compCTextMargin="0px auto auto auto"
            />
        </div>
        <div className="story" id="Prototype" style={{marginTop:'240px'}}>
            {/* <div className="desktop-prototype">
              <div className="componentE">
                  <div className="c-e-prototype-desktop">
                      <div className="v-text-container">
                          <h2 id="proto-v-text" style={{color:""}}>Prototype</h2>
                      </div>
                      <iframe id="prototype-desktop"src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FdJOOR6CIG7Jpxiv4D1r0Sl%2FUntitled%3Fpage-id%3D0%253A1%26node-id%3D101-446%26viewport%3D541%252C37%252C0.11%26t%3DudwY77qqIuS2LTXt-1%26scaling%3Dcontain%26content-scaling%3Dfixed%26starting-point-node-id%3D101%253A446%26show-proto-sidebar%3D1%26hide-ui%3D1" allowfullscreen></iframe>
                  </div>    
              </div>
            </div> */}
            <div className="desktop-prototype">
                {/* <div className="v-text-container">
                  <h2 id="proto-v-text" style={{color:""}}>Prototype</h2>
                </div> */}
                <iframe id="prototype-desktop"src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FYfK8sdfSR9UobvkLyMXLYO%2FHIFI%3Fpage-id%3D0%253A1%26node-id%3D507-4437%26viewport%3D-263%252C217%252C0.09%26t%3D89aHZPX2fhfcSlSS-1%26scaling%3Dscale-down%26content-scaling%3Dfixed%26starting-point-node-id%3D507%253A4437%26show-proto-sidebar%3D1%26hide-ui%3D1" style={{borderRadius:'1.8em', maxWidth:'900px'}} allowfullscreen></iframe>
            </div>
        </div>
        <div className="story" id="Research" style={{backgroundColor:'#F7F7F7'}}>
            <div className="v-text-container">
                <h2 id="proto-v-text" style={{color:'#999999', marginTop:'-80px'}}>UX Research</h2>
            </div>
            <div className="ux-research">
{/* 
            <div className="ux-r-intro">
                    <p>To come up with solutions, we progressed through iterations of analysis which can be divided into 3 steps. Ideas and opinions, Interpretation, and Integration.</p>
            </div> */}

              <ReserchBlockA
                BlockTitle="Story Board"
                BlockADetail="This process is the top 5 solutions, telling us what would happen if the users face issues raised in the problem statement and do not have 'Kitchr'."
                researchAIMG={kt_storyBoard}
                resAWidth="90%"
              />
              <ReserchBlockA
                BlockTitle="Persona Study"
                BlockADetail="Composing of 3 possible users which is a student who seeks more variety of food and would thrive if cooking with someone else."
                researchAIMG={kt_persona}
                resAWidth="90%"
              />
              <ReserchBlockA
                BlockTitle="Alternative Solutions"
                BlockADetail="Before coming to the character and their actions specifically to our target, the alternate tasks allow the process to develop a stronger choice that is viable and helpful for the problem statement in general."
                researchAIMG={kt_alt}
                resAWidth="90%"
              />
              <ReserchBlockB 
                BlockTitle1="Sketching Technique"
                BlockTitle2="Pros"
                BlockTitle3="Cons"
                BlockDetail1="The sketches start from the possibilities of the solution to the storyboard to check how users’ flows are when they have to deal with the problem statement when our solution is yet to exist. I chose to develop the project from the idea of how user flow should be on the Miro.com board and started creating the layout and wireframe of each page by Illustrator after pickup from the sketches, then transferred them into low and developed the high-fidelity on Figma desktop where most design and artwork did in Adobe Illustrator."
                BlockDetail2="It is fast to come up with the solution without checking with enough testers in the process which allows us to create a lot of ideas and sprint to the final product faster. Additionally, this approach makes sure we are on the right track to tackle the problem we propose in the beginning."
                BlockDetail3="There is not enough time to make sure if the flow really works or if the solution will really respond to the public. If we can add more phases where we can deal with data from either databases or interviews, I believe the app could be closer to reality."
              />
              <ReserchBlockA
                BlockTitle="Flow and Functionality Study"
                BlockADetail="Study of how to select and arrange each function and feature in a sequence that makes sense for users. The flows are considered from the tendency of users concerns from security, food safety, and convenience."
                researchAIMG={kt_flow1}
                resAWidth="90%"
              />    
              <div className="kt-qoc" style={{display:'flex'}}>
                  <img src={kt_qoc1} style={{width:'30%'}} alt="" />
                  <img src={kt_qoc2} style={{width:'30%'}} alt="" />
                  <img src={kt_qoc3} style={{width:'30%'}} alt="" />
              </div>
              {/* <div className="amigo-finding">
                <h4>Findings</h4>
                <div id="amigo-finding-no">
                  <h4 style={{backgroundColor:'#1E805E'}}>1</h4>
                  <p>
                    Many businesses  <b style={{color:'#1E805E'}}>misunderstood that improving their organization's financial and digital literacy will be difficult and time-consuming </b>. This is why they avoid adapting new product existed in the market. </p>
                </div>
                <div id="amigo-finding-no">
                  <h4 style={{backgroundColor:'#1E805E'}}>2</h4>
                  <p>It is important to  <b style={{color:'#1E805E'}}> keep users notified on the latest update </b> to leave an adequate time for planning and effective execution. </p>
                </div>
                <div id="amigo-finding-no">
                  <h4 style={{backgroundColor:'#1E805E'}}>3</h4>
                  <p>As the functions become more complex and technical, <b style={{color:'#1E805E'}}>we need to make the product as simple and intuitive as much as possible </b> regardless how integrated these functions to uses' daily life.</p>
                </div>
              </div> */}
              <details style={{marginLeft:'80px'}}>
                <summary>References</summary>
                <p style={{margin:'5px 0px 5px 0', fontSize:'12px', width:'800px'}} >
                • The University of Michigan Facts and Figures 2022. Michigan Creative, a unit of the Office of the Vice President for Communications. University of Michigan. https:// guides.lib.umich.edu/c.php?g=282964&p=1885442
                </p>
                <p style={{margin:'5px 0px 5px 0', fontSize:'12px', width:'800px'}} >
                •  Buhr, Michael. “University of Michigan Student Life.” International Center, University of Michigan, 20 Nov. 2020. https:// internationalcenter.umich.edu/sites/default/files/ Annual_Report.pdf
                </p>
                <p style={{margin:'5px 0px 5px 0', fontSize:'12px', width:'800px'}} >
                • Gibbons, Sarah. “UX Mapping Methods Compared: A Cheat Sheet.” Nielsen Norman Group, 5 November 2017, https://www.nngroup.com/articles/ux-mapping-cheat-sheet/. Accessed 30 November 2022.              
                </p>
                <p style={{margin:'5px 0px 5px 0', fontSize:'12px', width:'800px'}} >
                • “Chapter 2: The Process of Interaction Design” in Helen Sharp, Jennifer Preece, and Yvonne Rogers. 2019. Interaction Design: Beyond Human-Computer Interaction (5th ed.). Wiley.              
                </p>
                <p style={{margin:'5px 0px 5px 0', fontSize:'12px', width:'800px'}} >
                • Lidwell, William, et al. Universal Principles of Design, Revised and Updated : 125 Ways to Enhance Usability, Influence Perception, Increase Appeal, Make Better Design Decisions, and Teach through Design, Quarto Publishing Group USA, 2010. ProQuest Ebook Central, http://ebookcentral.proquest.com/lib/umichigan/detail.action?docID=3399678. Created from umichigan on 2021-07-14 19:35:09.               
                </p>
                <p style={{margin:'5px 0px 5px 0', fontSize:'12px', width:'800px'}} >
                • Images from https://unsplash.com/, Accessed November 2022:     Section 1:     User 1: Alexander Hipp, User 2: Dahiana Waszaj, User 3: Christian Buehner     Section 2:     Sugar: John Cutting, Spice: Timothy Newman, Garlic: Joe Green, Ketchup: DL Samuels, Salsa: Tai’s Capture, Chillies: Thomas M Evans, Mayonnaise: Evan Reimer, Fried Rice: Louis Hansel, Section 3: Short Grain, Red Bean, Flake, Corn, Brown Rice: Lukasz Rawa, Long Grain: Pierre Bamin, Granola: Abhishek Hajare, Section 4: Kitchen: Camylla Battani, Section 5: Rice: Lukasz Rawa, Spice: Nisuda Nirmantha, Meat: Cindie Hansen, Vegetable: Cyrus Crossan, Oil: Roberta Sorge, Fruit: Zaib Tsesh=517add7ab2c1                   
                </p>
                {/* <p style={{margin:'5px 0px 5px 0', fontSize:'12px', width:'800px'}} >
                • Images from https://www.argusfarmstop.com/, Accessed November 2022: Argus Logo 
                </p>
                <p style={{margin:'5px 0px 5px 0', fontSize:'12px', width:'800px'}} >
                • Images from https://peoplesfood.coop/, Accessed November 2022: People Food Logo
                </p>
                <p style={{margin:'5px 0px 5px 0', fontSize:'12px', width:'800px'}} >
                •  Images from https://commons.wikimedia.org/, Accessed November 2022: Kroger Logo (Originally from http://www.kroger.com/ Date 6 November 2019)
                </p>
                <p style={{margin:'5px 0px 5px 0', fontSize:'12px', width:'800px'}} >
                •  Images from https://en.wikipedia.org/, Accessed November 2022: Target Logo(Originally, date September 2007) 
                </p>
                <p style={{margin:'5px 0px 5px 0', fontSize:'12px', width:'800px'}} >
                • Images from https://commons.wikimedia.org/ Accessed November 2022: Visa Logo(Originally from http://www.visa.com/ Date 2005) 
                </p>
                <p style={{margin:'5px 0px 5px 0', fontSize:'12px', width:'800px'}} >
                • QOC Diagram Study Reference: MacLean, Allan, et al. Design Rationale: Concepts, Techniques, and Use, edited by Thomas P. Moran, Taylor & Francis Group, 2020. Accessed 26 October 2022.
                </p>
                <p style={{margin:'5px 0px 5px 0', fontSize:'12px', width:'800px'}} >
                • Prototype images from Unsplash.com, Pexels.com, RandomUser.me, ThisPersonDoesNotExist, Generated.photos
                </p>
                <p style={{margin:'5px 0px 5px 0', fontSize:'12px', width:'800px'}} >
                • Prototype icons Awesome, Ionic, Simple
                </p> */}
            </details> 
            </div>
        </div>
        <div className="story" id="Testing-Wireframes" style={{color:"white", background:'white'}}> 
            <div className="v-text-container">
              <h2 id="proto-v-text" style={{color:'#999999', marginTop:'-80px'}}>Testing</h2>
            </div>
            <div className="ux-research"> 
            <img src={kt_wireframe} alt="" style={{width:"100%", margin:'0px 0 80px 0'}}/>
            </div>
        </div>
        <div className="story" style={{padding:'80px 138px 40px 138px'}}> 
          <ProjectNavigator 
            BlockTitle1="Cos"
            BlockDetail1="FinTech "
            to1="/cos-finance"
            BlockTitle2="AmíGo"
            BlockDetail2="Travel "
            to2="/amigo"
            BlockTitle3="Racing Susan"
            BlockDetail3="FinTech "
            to3="/racing-susan"
            />
          <Footer />
        </div>
      </div>
    </div>
    <div className="mobile">
        <CardMobile 
          projectName="Kitchr"
          projectImg={kt_banner}
          projectImgWidth="80%"
          projectImgBGColor="#1E805E"
          projectDescribtion="Kitchr bridges the gap between food communities in towns, connecting people and food suppliers closer for authenticity and variety of choices!"
          bannerChip1="Product Design"
          bannerChip2="14 Weeks"
          bannerChip3="Design and Research Lead"
          bannerChip4="Mobile"
          challenges="The problem is the lack of affordable food supplies where wasted food remains an issue. This is where Kitchr comes in to help bridge these gaps with solutions for people to meet up and start sharing."
          roles="We started with prototypes targeting university-age users, and then scaling up to larger cities and towns with similarity in the scarce variety for food options."
          tools="Figma | Google Suites | Miro | Paper and Pen | Illustrator" 
          bgIMG={kt_ads}
          bgColor="#E7FFF8"
          compATitle="Do grocery shopping and cook locally"
          compADetail="Build a stronger food community."
          textMargin="0 0 0 -50px"
          blockATextColor="#474747"
          imgSet1={kt_set1}
          imgSet2={kt_set2}
          imgSet3={kt_set3}
          set1Head="Save more with options"
          set1Detail="Save up to $700 annually! Turning grocery shopping into a mindful activity, where you can buy, share, and cook meals with your local friends."
          set2Head="Connected & Safe"
          set2Detail="We checked and double-checked to make sure your local is safe! We would like everyone to enjoy food, cooking, and shopping together happily!"
          set3Head="Show the world your kitchen?"
          set3Detail="Be a big person and invite a friend! Host a cooking event, and make the community your favorite dish!"
          destination="https://www.figma.com/proto/YfK8sdfSR9UobvkLyMXLYO/HIFI?page-id=0%3A1&node-id=507-4437&viewport=-263%2C217%2C0.09&t=89aHZPX2fhfcSlSS-1&scaling=scale-down&content-scaling=fixed&starting-point-node-id=507%3A4437&show-proto-sidebar=1"
          bgcolor="rgb(0,106,255)"
          holder="Prototype"
        />
    </div>
    </>
  )
}

export default ProjectKitchr