import React, { useRef, useEffect } from 'react';
import '../css/Main.css';
import '../css/Elements.css'
import '../css/Projects.css';
import Button from './Button';

function ProjectComponentC({compCMargin, compBTitle, compBDetail, logo, BGRoundColor, IMGMargin, IMGScale, compCTextColor, compCTextMargin, CompCAnimation}) {


  return (
        <div className="componentC" style={{margin:compCMargin}}>
            <div className="c-c-img" style={{backgroundColor:BGRoundColor, margin:IMGMargin}}>
                <div className="c-c-img-bg"></div>
                <img src={logo} style={{scale:IMGScale, animation:CompCAnimation}} alt=""/>
            </div>
            <div className="c-c-text">
               <div className="c-c-wraper" style={{position:'relative', verticleAlign:'middle', margin:compCTextMargin}}>
                  <h2 style={{color:compCTextColor}}>{compBTitle}</h2>
                  <p>{compBDetail}</p>
               </div>
            </div>
        </div>
  )
}

export default ProjectComponentC