import React, { useRef, useEffect } from 'react';
import Navbar from './Navbar';
import '../css/Main.css';
import '../css/Elements.css'


const HeroImage = ({bannerImage, bannerWidth, bannerImgMargin, borderR, HeroBGcolor, spinner, HeroMargin,HeroEdge, HeroEdgeL}) => {
        const elementRef = useRef(null);
      
        useEffect(() => {
          const handleScroll = () => {
            if (window.scrollY >= 900) {
              elementRef.current.style.right = '-100%'; 
            } else {
              elementRef.current.style.right = '0'; 
            }
          };
          window.addEventListener('scroll', handleScroll);

          return () => {
            window.removeEventListener('scroll', handleScroll);
          };
        }, []);


  return (
        <>
            <div className="banner-products" id="banner-images" ref={elementRef} style={{borderRadius:borderR, background:HeroBGcolor, marginTop:HeroMargin, marginRight:HeroEdge, marginLeft:HeroEdgeL}}>
              <svg id="rotate" viewBox="0 0 200 200" width="200" height="200" style={{display:spinner}}> <defs> 
                <path id="circle" d="M 100, 100 m -75, 0 a 75, 75 0 1, 1 150, 0 a 75, 75 0 1, 1 -150, 0"/> </defs> 
                <circle cx="100" cy="100" r="80" fill="none" stroke="rgba(0,106,255,1)" stroke-width="30" opacity="0.5"/>
                <text width="200" id="rt" fill="white" font-size="12"> 
                  <textPath startOffset="48%" href="#circle" text-anchor="middle"> 
                    Solution to quick and efficient transaction services 
                  </textPath> 
                  <textPath startOffset="94%" href="#circle" text-anchor="middle"> 
                   • Cos Finance • 
                  </textPath> 
                </text> 
              </svg>
              <img src={bannerImage} style={{width:bannerWidth, margin:bannerImgMargin}} alt="ProjectBanner" id="banner-product" />
            </div>
        </>
     );
};

export default HeroImage