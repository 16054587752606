// export const scrollThere = (hashID) => {
//     const e = document.getElementById(hashID);
//     if (e) {
//         const pos = e.getBoundingClientRect().top + window.pageYOffset;
//         e.scrollIntoView({pos, behavior:'smooth', block: 'start'});
//         setTimeout(() => {
//           window.scrollBy({ top: -200, behavior: 'smooth' });
//         }, 0);
//     };
// }

export const scrollThere = (hashID) => {
  const element = document.getElementById(hashID);
  if (element) {
    const yOffset = -100; // Offset to move up by 200px
    const yCoordinate = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: yCoordinate, behavior: 'smooth' });
  }
};