import React from 'react'
import {Link} from 'react-router-dom';
import Navbar from './elements/Navbar';
import profile from './img/homepage/profile.png'

import './css/Main.css'
import './css/About.css'
import ProjectNavigator from './elements/ProjectNavigator';
import Footer from './elements/Footer';

function Aboupage() {
  return (
  <>
    <Navbar />
    <div className='desktop'>
       <div className="aboutme-frames">
          <h1>ABOUT</h1>
          <img id="profile-picture" src={profile} alt="" />
          <div className="about-span">
            <div id="about-detail">
              <span><h2 id="brann">B</h2></span>
              <div id="ab-detail">
                  <h4>Best Decisions</h4>
                  <p>Pursue design careers. From interior to product design, I have learned that we create how kind the human world could be from day to day.</p>
              </div>
            </div>
            <div id="about-detail">
              <span><h2 id="brann">R</h2></span>
              <div id="ab-detail">
                  <h4>Respect Others</h4>
                  <p>Being a team player is important, things might work when we do it alone but it won't build Rome to where it was.</p>
              </div>
            </div>
            <div id="about-detail">
              <span><h2 id="brann">A</h2></span>
              <div id="ab-detail">
                  <h4>Aim High</h4>
                  <p>IIt doesn't need to be the Moon I am pushing, but if I keep working on myself, my work should positively impact society.</p>
              </div>
            </div>
            <div id="about-detail">
              <span><h2 id="brann">N</h2></span>
              <div id="ab-detail">
                  <h4>New Things</h4>
                  <p>Open up to new things always surprises me, sometimes good other times not much. That being said, discovering what works and what's not widens my perspective.</p>
              </div>
            </div>
            <div id="about-detail">
              <span><h2 id="brann">N</h2></span>
              <div id="ab-detail">
                  <h4>Next!</h4>
                  <p>I am working on a platform idea that could revolutionize the way we shop! Exciting! But more importantly, I'm open to work and would be so happy to hear from you!</p>
              </div>
            </div>
          </div>
          <div className="details">
            <h4>Hello!</h4>
            <p style={{marginBottom:'100px'}}> <b>This is Bannawit Khattiyanont and I go by "Brann", recently graduated from MSI program at the University of Michigan.</b> </p>
            <div className="about-small-detail" id="ab-sd-left">
              <h4>Personality</h4>
              <p  id='ab-det-yellow'>ENFJ and Type 9w1, basically I like to communicate and make things work! 💬</p>
            </div>
            <div className="about-small-detail" id="ab-sd-right">
              <p  id='ab-det-red'>✍🏽 I love sketching, drawing, and painting. I started biking to commute, but it's getting fun!</p>
              <h4>Hobbies</h4>
            </div>
            <div className="about-small-detail" id="ab-sd-right">
              <p  id='ab-det-red'>🎮 Cities: Skylines is one of my favorite games to play. The Sims and Mario Kart also join the top of the list!</p>
              <h4>Games</h4>
            </div>
            <div className="about-small-detail" id="ab-sd-right">
              <p  id='ab-det-red'>🎹 Mellow music is my vibe in general but not for the gym.</p>
              <h4>Vibes</h4>
            </div>
            <div className="about-small-detail" id="ab-sd-left">
              <h4>Fun Fact</h4>
              <p  id='ab-det-yellow'>I might remember how long one astronomical unit (AU) is. But why? 🌎</p>
            </div>
            <div className="about-small-detail" id="ab-sd-left">
              <h4>Favorite Styles</h4>
              <p  id='ab-det-yellow'>Mid-Century, Renaissance, and Modern 🎨</p>
            </div>
          </div>
        </div>
        <div className="story" style={{padding:'80px 138px 40px 138px'}}> 
          <ProjectNavigator 
            BlockTitle1="Cos"
            BlockDetail1="FinTech "
            to1="/cos-finance"
            BlockTitle2="AmíGo"
            BlockDetail2="Travel "
            to2="/amigo"
            BlockTitle3="Racing Susan"
            BlockDetail3="FinTech "
            to3="/racing-susan"
            />
     </div>
    </div>
     <div className="mobile">
      <div className="mb-about-wrapper">
        <h1>ABOUT</h1>
            <img id="profile-picture" src={profile} alt="" />
        </div>
        <h2 style={{fontSize:"40px"}}>B R A N N</h2>
        <p>This is Bannawit Khattiyanont and I go by "Brann", recently graduated from MSI program at the University of Michigan.</p>
        <div className="mb-about-group">
          <h3>Personality</h3>
          <p>ENFJ and Type 9w1, basically I like to communicate and make things work! 💬</p>
          <h3>Hobbies</h3>
          <p>✍🏽 I love sketching, drawing, and painting. I started biking to commute, but it's getting fun!</p>
          <h3>Games</h3>
          <p>Cities: Skylines is one of my favorite games to play. The Sims and Mario Kart also join the top of the list!</p>
          <h3>Vibes</h3>
          <p>🎹 Mellow music is my vibe in general but not for the gym.</p>
          <h3>Fun Fact</h3>
          <p>I might remember how long one astronomical unit (AU) is. But why? 🌎</p>
          <h3>Styles</h3>
          <p>Mid-Century, Renaissance, and Modern 🎨</p>
        </div>
     </div>
     <div className="ctrl" style={{marginTop:'-40px'}}>
        <Footer />
     </div>
  </>
  )
}

export default Aboupage