import React, {useState, useEffect, useRef} from 'react'
import {useLocation} from 'react-router-dom';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import "../css/Elements.css";
import { scrollThere } from '../functions/scrollThere';


function Breadcrumb({ currentPage, section1, section2, section3, section4, top, section1Lable, section2Lable, section3Lable, section4Lable, breadcrumbRight}) {

    useEffect(() => {
        function scrollFunction() {
          const section_navigator = document.getElementById("section_navigator");
          if (section_navigator) {
            if (document.body.scrollTop > 1400 || document.documentElement.scrollTop > 1400) {
              section_navigator.style.top = "10px";
            } else {
              section_navigator.style.top = "-200px";
            }
          }
        }
    
        window.onscroll = scrollFunction;
    
        // Cleanup
        return () => {
          window.onscroll = null;
        };
      }, []);
        

  return (
    <div id="section_navigator" style={{right:breadcrumbRight}}>
        <HashLink id="section" onClick={() => scrollThere(top)}><h2 style={{backgroundColor:'white', padding:'4px 20px 4px 20px', borderRadius:'50em'}}>{currentPage}</h2></HashLink>
        <div className="sections">
            <HashLink id="section" onClick={() => scrollThere(section1)}>       
                {section1Lable}
            </HashLink>
            <HashLink id="section" onClick={() => scrollThere(section2)}>
                {section2Lable}
            </HashLink>
            <HashLink id="section" onClick={() => scrollThere(section3)}>
                {section3Lable}
            </HashLink>
            <HashLink id="section" onClick={() => scrollThere(section4)}>
                {section4Lable}
            </HashLink>
        </div>
        <div className="menu-short">
            <Link to='/'      id="nav">Home</Link>
            <Link to="mailto:bannawit@umich.edu?subject=We're interested in your work&body=Hey Brann,%0A%0A We are interested in your project and would like to schedule a meeting, when is your availability? %0A%0A Best" id="nav">Email</Link>
        </div>
    </div>
  )
}

export default Breadcrumb