import React, { useRef, useEffect } from 'react';
import '../css/Main.css';
import '../css/Elements.css'
import '../css/Projects.css';
import '../css/Research.css';
import Button from './Button';

function ReserchBlockD({BlockIMG1,BlockTitle1,BlockIMG2,BlockTitle2, textColor}) {

   
  return (
        <div className="ResearchD">
            <div className="b-c-texts">
              <h3 style={{color:textColor}}>{BlockTitle1}</h3>
              <img src={BlockIMG1} alt="" />
           </div>
           <div className="b-c-texts">
              <h3 style={{color:textColor}}>{BlockTitle2}</h3>
              <img style={{width:'100%'}}src={BlockIMG2} alt="" />
           </div>
        </div>
  )
}

export default ReserchBlockD