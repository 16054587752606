import React, { useRef, useEffect } from 'react';
import '../css/Main.css';
import '../css/Elements.css'
import '../css/Projects.css';
import '../css/Research.css';
import Button from './Button';

function ReserchBlockC({BlockIMG1,BlockTitle1,BlockDetail1,BlockIMG2,BlockTitle2,BlockDetail2}) {

   
  return (
        <div className="ResearchC">
           <img src={BlockIMG1} alt="" />
           <div className="b-c-texts">
              <h3>{BlockTitle1}</h3>
              <p>{BlockDetail1}</p>
           </div>
           <img src={BlockIMG2} alt="" />
           <div className="b-c-texts">
              <h3>{BlockTitle2}</h3>
              <p>{BlockDetail2}</p>
           </div>
        </div>
  )
}

export default ReserchBlockC