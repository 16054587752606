import React, { useRef, useEffect } from 'react';
import '../css/Main.css';
import '../css/Elements.css'
import '../css/Projects.css';
import Button from './Button';

function ProjectComponentA({bgIMG, bgColor, CompAPadding, compATitle, compADetail, opa,bgIMGcolor, CompAImgWidth, CompAImgMargin, BGBlend, blockATextColor, blockATextMargin}) {
  return (
     <div className="componentA" style={{padding:CompAPadding, background:bgColor, opacity:opa, backgroundImage:bgIMGcolor, backgroundBlendMode:BGBlend}}>
        <img src={bgIMG} style={{opacity:"1", width:CompAImgWidth, margin:CompAImgMargin}} alt="" />
        <div className="c-a-words" style={{color:blockATextColor, textAlign:'left', margin:blockATextMargin}}>
            <h2>{compATitle}</h2>
            <p style={{marginTop:'30px'}}>{compADetail}</p>
        </div>
     </div>
  )
}

export default ProjectComponentA