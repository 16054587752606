import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { scrollThere } from './functions/scrollThere';
// CSS
import './css/Main.css'
import './css/Projects.css'
import './css/Research.css'
import './css/Elements.css'
// COMPO
import HeroBanner from './elements/HeroBanner'
import Breadcrumb from './elements/Breadcrumb';
import ProjectBrief from './elements/ProjectBrief';
import ProjectComponentA from './elements/ProjectComponentA';
import ProjectComponentB from './elements/ProjectComponentB';
import ProjectComponentC from './elements/ProjectComponentC';
import ProjectComponentC2 from './elements/ProjectComponentC2';
import Project from './elements/ProjectComponentC';
import ProjectComponentE from './elements/ProjectComponentE';
import ReserchBlockA from './elements/ReserchBlockA';
import ReserchBlockB from './elements/ReserchBlockB';
import ReserchBlockC from './elements/ReserchBlockC';
import ReserchBlockD from './elements/ReserchBlockD';
import Footer     from './elements/Footer';
import ProjectNavigator from './elements/ProjectNavigator';
import CardMobile from './elements/CardMobile';
// IMG
import ff_home from './img/homepage/projectFoodFight/ff_home.png'
import ff_set from './img/homepage/projectFoodFight/ff_set.png'
import ff_set1 from './img/homepage/projectFoodFight/ff_set1.png'
import ff_set2 from './img/homepage/projectFoodFight/ff_set2.png'

import kt_stories from './img/homepage/projectKitchr/kt_stories.png'
import kt_ads from './img/homepage/projectKitchr/kt_ads.png'
import kt_set1 from './img/homepage/projectKitchr/kt_group1.png'
import kt_set2 from './img/homepage/projectKitchr/kt_group2.png'
import kt_set3 from './img/homepage/projectKitchr/kt_group3.png'
import kt_storyBoard from './img/homepage/projectKitchr/kt_storyBoard.png'
import kt_persona from './img/homepage/projectKitchr/kt_persona.png'
import kt_alt from './img/homepage/projectKitchr/kt_flow1.png'
import kt_flow1 from './img/homepage/projectKitchr/kt_alt.png'
import kt_qoc1 from './img/homepage/projectKitchr/kt_qoc1.png'
import kt_qoc2 from './img/homepage/projectKitchr/kt_qoc2.png'
import kt_qoc3 from './img/homepage/projectKitchr/kt_qoc3.png'
import kt_wireframe from './img/homepage/projectKitchr/kt_wireframe.png'

function ProjectFoodFight() {

    // linear-gradient(82deg, rgba(255,255,255,1) 24%, rgba(86,134,214,1) 63%)

  window.scrollTo(0,0);
  return (
    <>
    <div className="desktop">
      <div id="project-top">
        <HeroBanner 
        projectTitle="FoodFight"
        textBlockMarginTop="120px"
        projectHeadline="FoodFight is an app from FoodFight Inc. which I interned with in summer 2023. The app connects friends and football fans with fun games on food. The main features include ordering food at partner restaurants to be a part of users' game and live score update channels to keep them engaged."
        bannerImage={ff_home}
        bannerWidth="50%"
        bannerImgMargin="0 0 0 140px"
        HeroBGcolor="#ff6400"
        borderR="50em 0 0 50em"
        bannerChip1="Product Design"
        bannerChip2="12 Weeks"
        bannerChip3="Intership"
        bannerChip4="Mobile"
        bgColor="white"
        color="rgba(255,255,255,0.8)"
        tagBG="#ff6400"
        tagCl="black"
        spinner="none"
        />
      </div>
      <Breadcrumb 
        top="project-top"
        section1="Introduction"
        section2="Product"
        section3="Prototype"
        section4="Research"
        section1Lable="Introduction"
        section2Lable="Experiences"
        section3Lable="Prototype"
        section4Lable="Next"
        currentPage="FoodFight"
      />
      <div className="stories">
        <div className="story" id="Introduction">
          <ProjectBrief 
          challenges="Lack of fun and light activities for friends and sports fans to enjoy during the game."
          roles="Design and facilitate and lead solutions and ideas to deliver the best information architecture with user experience research results and digital markets."
          tools="Figma | Google Suites | Photoshop | Illustrator"
          />
        </div>
        <div className="story" id="Product">
          <div className="intern">
            <h2>It was a fun experience!</h2>
            <p style={{width:'60%'}}>As a team, we discussed the goal and brief from the previous research process before getting into more design meetings. The projects include Client Side Applications, Structure, and Websites. During the twelve-week time course, I have spent time brainstorming, drafting, prototyping, presenting, and redesigning or adjusting per the team's suggestion.</p>
          </div>
          <div className="intern2">
            <h2>Recap on what I contributed!</h2>
            <p style={{width:'60%'}}>Not just designing, I also conceptualized the interface experiences to encourage and excite users’ competitive self, and Collaborative working to simplify user flow for decision making through internal Agile workflow!</p>
          </div>
        </div>
        <div className="story" id="Prototype" style={{marginTop:'240px'}}>
              <ReserchBlockA
                BlockTitle="Sample on our past prototype progress"
                BlockADetail=""
                researchAIMG={ff_set}
                resAWidth="100%"
                imgPadding="20px"
              />  
        </div>
        <div className="story" id="Research" style={{backgroundColor:'white'}}>
        </div>
        <div className="story" id="Testing-Wireframes" style={{color:"white", background:'white'}}> 
        </div>
        <div className="story" style={{padding:'80px 138px 40px 138px', margin:'-300px 0 0 0'}}> 
          <ProjectNavigator 
            BlockTitle1="Cos"
            BlockDetail1="FinTech "
            to1="/cos-finance"
            BlockTitle2="AmíGo"
            BlockDetail2="Travel "
            to2="/amigo"
            BlockTitle3="Racing Susan"
            BlockDetail3="FinTech "
            to3="/racing-susan"
            />
          <Footer />
        </div>
      </div>
    </div>
    <div className="mobile">
        <CardMobile 
          projectName="FoodFight"
          projectImg={ff_home}
          projectImgWidth="60%"
          projectImgBGColor="#ff6400"
          projectDescribtion="FoodFight is an app from FoodFight Inc. which I interned with in summer 2023. The app connects friends and football fans with fun games on food. The main features include ordering food at partner restaurants to be a part of users' game and live score update channels to keep them engaged."
          bannerChip1="Product Design"
          bannerChip2="12 Weeks"
          bannerChip3="Internship"
          bannerChip4="Mobile"
          challenges="Lack of fun and light activities for friends and football friends to enjoy during the game."
          roles="Design and facilitate and lead solutions and ideas to deliver the best information architecture with user experience research results and digital markets."
          tools="Figma | Google Suites | Miro | Paper and Pen | Illustrator"
          compATitle="It was a fun experience!"
          compADetail="As a team, we discussed the goal and brief from the previous research process before getting into more design meetings. The projects include Client Side Applications, Structure, and Websites. During the twelve-week time course, I have spent time brainstorming, drafting, prototyping, presenting, and redesigning or adjusting per the team's suggestion."
          textMargin="0 0 0 50px"
          bgColor="#f4ab0c"
          blockATextColor="#474747"
          imgSet1={ff_set2}
          imgSet2={ff_set1}
          set1Head="Some of the progress we had!"
          set1Detail=""
          set2Head="Recap on what I contributed!"
          set2Detail="Not just designing, I also conceptualized the interface experiences to encourage and excite users’ competitive self, and Collaborative working to simplify user flow for decision making through internal Agile workflow!"
          destination="https://www.getfoodfight.com/"
          bgcolor="rgb(0,106,255)"
          holder="FoodFight Demo"
        />
    </div>
    </>
  )
}

export default ProjectFoodFight