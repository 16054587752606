import React, { useRef, useEffect } from 'react';
import '../css/Main.css';
import '../css/Elements.css'
import '../css/Projects.css';
import Button from './Button';

function ProjectComponentE({figma,image, imgWidth, prototypeColor}) {

   
  return (
        <div className="componentE">
            <div className="c-e-prototype">
                 {/* <div className="v-text-container">
                     <h2 id="proto-v-text" style={{color:prototypeColor}}>Prototype</h2>
                 </div> */}
                 <iframe id="prototype"src={figma} allowfullscreen></iframe>
            </div>
            <div className="c-e-overall">
               <div className="c-e-frame">
                  <img src={image} id="overall" style={{width:imgWidth}} alt="" />
               </div>
            </div>
        </div>
  )
}

export default ProjectComponentE