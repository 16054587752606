import React from 'react'
import Navbar from './elements/Navbar'

import './css/Project_version_2.css'

import HeroImage from './elements/HeroImage'
import Breadcrumb from './elements/Breadcrumb'
import ProjectComponentE from './elements/ProjectComponentE'
import Footer from './elements/Footer'
import ProjectNavigator from './elements/ProjectNavigator'
import ReserchBlockD from './elements/ReserchBlockD'
import CardMobile from './elements/CardMobile';

import cos_banner from '../components/img/homepage/projectCos/Version2/cos-top-banner.png'
import cos_overall from '../components/img/homepage/projectCos/Version2/cos-ov-banner.png'
import cos_budget from '../components/img/homepage/projectCos/Version2/cos-product-focus-budget.png'
import cos_help from '../components/img/homepage/projectCos/Version2/cos-premium-help.png'
import cos_spending from '../components/img/homepage/projectCos/Version2/cos-spending.png'
import cos_friends from '../components/img/homepage/projectCos/Version2/cos-friends.png'
import cos_tips from '../components/img/homepage/projectCos/Version2/cos-tips.png'
import cos_rewards from '../components/img/homepage/projectCos/Version2/cos-rewards.png'
import cos_warning from '../components/img/homepage/projectCos/Version2/cos-warning.png'
import cos_colors from '../components/img/homepage/projectCos/Version2/cos-colors.png'
import cos_ads from '../components/img/homepage/projectCos/Version2/cos-ads.png'
import cos_typos from '../components/img/homepage/projectCos/Version2/cos-typos.png'
import cos_arts from '../components/img/homepage/projectCos/Version2/cos-arts.png'
import cos_prototype from '../components/img/homepage/projectCos/Version2/cos-prototype.png'
import cos_bigpicture from '../components/img/homepage/projectCos/Version2/cos_identify_diagram.svg'
import cos_opinions from '../components/img/homepage/projectCos/Version2/cos_identify_opinions.svg'
import cos_rs_icon1 from '../components/img/homepage/projectCos/Version2/cos_rs_icon1.png'
import cos_rs_icon2 from '../components/img/homepage/projectCos/Version2/cos_rs_icon2.png'
import cos_rs_icon3 from '../components/img/homepage/projectCos/Version2/cos_rs_icon3.png'
import cos_sketch1 from './img/homepage/projectCos/cos_scenario1.png'
import cos_sketch2 from './img/homepage/projectCos/cos_scenario2.png'
import cos_sketch3 from './img/homepage/projectCos/cos_scenario3.png'
import cos_arch1 from './img/homepage/projectCos/cos-ux-dir1.svg'
import cos_arch2 from './img/homepage/projectCos/cos-ux-dir2.svg'
import cos_wireframe1 from './img/homepage/projectCos/cos-wireframes1.png'
import cos_wireframe2 from './img/homepage/projectCos/cos-wireframes2.png'
import cos_wireframes from '../components/img/homepage/projectCos/Version2/cos-wireframes.png'

import cos_set1 from './img/homepage/projectCos/cos-set1.png'
import cos_set3 from './img/homepage/projectCos/cos-set3.png'
import cos_set4 from './img/homepage/projectCos/cos-set4.png'

function ProjectCos_New() {
   window.scrollTo(0,0);
  return (
    <>
    <div className="desktop">
    <Navbar />
    <div className="project-template">
        <div className="pb-container">
            <div className="pb-banner-prototype">
                <HeroImage 
                bannerImage={cos_banner}
                borderR="100em 0 0 100em"
                HeroBGcolor="#0966FF"
                spinner="none"
                HeroMargin="0px"
                HeroEdge="0px"
                HeroEdgeL="30px"
                bannerWidth="140%"
                bannerImgMargin="-180px -90px"
                />
            </div>
            <div className="pb-banner-container">
                <div className="pb-banner-texts">
                    <h1>Cos Finance</h1>
                    <p style={{marginTop:'0'}}>Cos is a banking fintech that <b>helps young professionals manage their finances more intuitively.</b> Aiming to ease monetary stress which blocks creativity, unleashed the potential to transform the landscape of their expertise.</p>
                    <div className="pb-chips-wrap">
                        <p className="pb-chip" id="cos-chip">16 Weeks</p>
                        <p className="pb-chip" id="cos-chip">Lead Design</p>
                        <p className="pb-chip" id="cos-chip">Mobile</p>
                    </div>
                </div>
                <div className="pb-briefs">
                    <div className="pb-brief-wrap">
                        <div className="pb-brief-ea">
                            <h4 id="pb-brief-ea-t">Challenges</h4>
                            <p id="pb-brief-ea-t">
                            Cross-platforms, multiple accounts, and significantly, <b> users' stress from life </b> which prevent them from living free of heavy cognitive load on money management.    
                            </p>
                        </div>
                        <div className="pb-brief-ea">
                            <h4 id="pb-brief-ea-t">Big Ideas</h4>
                            <p id="pb-brief-ea-t">
                            With a convenient <b>flow of use, AI technology, and financial behavioral support,</b> we firmly believe our users get the best from accessibility use and user-centric designs.   
                            </p>
                        </div>
                        <div className="pb-brief-ea">
                            <div className="pb-chips-wrap" style={{marginTop:'0', marginBottom:'0'}}>
                                <p className="pb-chip" id="cos-chip-tool">Figma</p>
                                <p className="pb-chip" id="cos-chip-tool">Illustrator</p>
                                <p className="pb-chip" id="cos-chip-tool">Photoshop</p>
                            </div>
                            <div className="pb-chips-wrap">
                                <p className="pb-chip" id="cos-chip-tool">Miro</p>
                                <p className="pb-chip" id="cos-chip-tool">MS Office</p>
                                <p className="pb-chip" id="cos-chip-tool">Notebook</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="pd-container">
            <div className="pd-scope-container">
                <div className="pd-im">
                    <h3 id="pd-im-st">38%</h3>
                    <p>Quicker to complete the tasks.</p>
                </div>
                <div className="pd-im">
                    <h3 id="pd-im-st">$720</h3>
                    <p>Saved from late fees yearly.</p>
                </div>
                <div className="pd-im">
                    <h3 id="pd-im-st">1.8X</h3>
                    <p>saving expect to increase.</p>
                </div>
            </div>
            <div className="pd-overview-container" id="Product">
                <img src={cos_overall} id="big_banner" alt="" />
            </div>
            <div className="pd-product-focus" style={{backgroundColor:'white'}}>
                <div className="pd-p-l">
                    {/* <h2 id="pd-p-l-t" style={{color:'#0966FF'}}>Seriously tracking</h2> */}
                    <h2 id="on-track">BE</h2>
                    <h2 id="on-track">ON-</h2>
                    <h2 id="on-track">TRACK</h2>
                    <p id="pd-p-l-t" style={{width:'50%', transform:'skew(00deg,-3deg)'}}>With better algorithm categorizing your expenses and income, analyzed with AI and keep you posted on what to do.</p>
                </div>
                <img src={cos_budget} className="align-right" id="reg_banner" alt="" />
            </div>
            <div className="pd-ser-container">
                <div className="pd-s-ea">
                    <div className="pd-s-ea-t">
                        <h3 id="fts-72">Synced spending patterns</h3>
                        <p>With the review and balance feature, making sure you understand what you need.</p>
                    </div>
                    <img src={cos_spending} id="ser-big-banner" alt="" />
                </div>
                <div className="pd-s-ea">
                    <div className="pd-s-ea-t">
                        <h3>Rewarding</h3>
                        <p>Life is a game, so is your money!</p>
                    </div>
                    <img src={cos_rewards} style={{width:'60%'}} alt="" />
                </div>
                <div className="pd-s-ea">
                    <div className="pd-s-ea-t">
                        <h3>Who influences you?</h3>
                        <p>Analyze behavior influencers</p>
                    </div>
                    <img src={cos_friends} style={{width:'90%', marginRight:'-80px'}} alt="" />
                </div>
                <div className="pd-s-ea">
                    <div className="pd-s-ea-t">
                        <h3>Tricks and Tips</h3>
                        <img src={cos_tips} style={{width:'70%'}} alt="" />
                        <p>You are not alone in this!</p>
                    </div>
                </div>
                <div className="pd-s-ea">
                    <div className="pd-s-ea-t">
                            <h3 id="fts-72">Goal Oriented</h3>
                            <p>Be on alert, keep building your wealth.</p>
                    </div>
                    <img src={cos_warning} style={{width:'70%', top:'54%'}} alt="" />
                </div>
            </div>
            <div className="pd-premium-container">
                <div className="pd-p-t-top">
                    <h2>Thoughtful and Care</h2>
                    <p>Feeling ignored often gets users quit trying, not with us. We prioritize fixing your issues, making financial management smooth and goal-driven for you! </p>
                </div>
                <img src={cos_help} id="middle-img" alt="" />
                <div className="pd-p-t-bottom">
                    <div className="pd-p-t-b-l">
                        <h3>Help Center</h3>
                        <p> <b>Available 24/7</b> to assist, offering real-person and automated support to keep you at ease.</p>
                    </div>
                    <div className="pd-p-t-b-m">
                        <h3>Troubleshooting</h3>
                        <p> <b>Different OS, different problem</b>, but we’re here to assist and fix your issues no matter what.</p>
                    </div>
                    <div className="pd-p-t-b-r">
                        <h3>AI Analysing</h3>
                        <p>From financial behavior to planning, <b>we’ve got everything you need</b> within this one app!</p>
                    </div>
                </div>
            </div>
            <div className="pd-branding-conmtainer">
                {/* <h2 style={{color:'white'}}>Visual and Interface</h2> */}
                <div className="pd-b-wrap">
                    <img src={cos_arts} style={{width:'100%'}} alt="" />                                                
                </div>
            </div>
        </div>
        <div className="p-prototype-container">
            <div className="story" id="Prototype">
                    <div className="p-pt-wrap">
                        <div className="p-pt-wrap-l">
                            <h2 id='title-h2' style={{width:'100%', marginBottom:'36px'}}>Check it out!</h2>
                            <a  className="clickable" href="https://www.figma.com/proto/241A3mUMzS2qWDJjhZ3xvx/COS?page-id=1%3A4&node-id=244-13433&node-type=FRAME&viewport=1032%2C771%2C0.68&t=qmMBLq6rKD2iBPEM-1&scaling=scale-down&content-scaling=fixed&starting-point-node-id=244%3A13433&show-proto-sidebar=1" target="_blank" rel="noopener noreferrer">Prototype</a>
                        </div>
                        <div className="p-pt-wrap-r">
                            <img src={cos_prototype} style={{width:'100%'}} alt="" />
                        </div>
                    </div>
            </div>
        </div>
        <div className="pr-container">
            <div className="pd-scope-container" style={{marginTop:'0px'}}>
                <div className="pd-im">
                    <h3 id="pd-im-st">80+</h3>
                    <p>User ideas from market study, survey, and interview.</p>
                </div>
                <div className="pd-im">
                    <h3 id="pd-im-st">3</h3>
                    <p>Testing and Evaluation: Heuristic, A/B, and Usability.</p>
                </div>
                <div className="pd-im">
                    <h3 id="pd-im-st">5+</h3>
                    <p>Big iterations to improve user experience and designs.</p>
                </div>
            </div>
            <div className="pr-mapping-container" >
                <h2 id="title-h2">So, how do we get here?</h2>
            </div>
            <div className="pr-research-wrap">
                <div className="pr-r-ea">
                    <div className="pr-r-ea-t">
   
                        <h3 id="research-header">"Systemic issues interupt user experiences"</h3>

                        <p><b>Digital finance activities can be faster.</b> It could be much faster than ATMs and should be as convenient as swiping credit cards.</p>
                        <p><b>An overloaded backend can interrupt flows.</b> An application that requires too frequent fetch requests can end up crashing the program.</p>
                        <p><b>Identity verification is vital but slow.</b> Human errors prevent seamless experiences, and technologies should assist not replacing them.</p>
                    </div>
                    <div className="pr-r-ea-img">
                        <img src={cos_bigpicture} style={{width:'100%'}} alt="" />
                    </div>
                </div>
                <div className="pr-r-ea">
                    <div className="pr-r-ea-t">
   
                        <h3 id="research-header">"Transactions take priority. Technical issues cause delays"</h3>
                        <p>
                        <b>Transfering is the top function to get users open to the apps</b>, then checking balance and due dates on savings and credit card accounts.
                        </p>
                        <p>From about 70 opinions, <b>most issues are back-end issues</b>, delays, and crashes for instance. While user flow, UI consistency, and readability are the top issues.
                        </p>

                    </div>
                    <div className="pr-r-ea-img" style={{textAlign:'center', margin:'auto'}}>
                        <img src={cos_opinions} style={{width:'70%'}} alt="" />
                    </div>
                </div>
                <div className="pr-r-ea-3col">
                    <div className="pr-r-ea-col">
                        <div className="pr-r-ea-col-img">
                            <img src={cos_rs_icon1} alt="" id="rs-icon"/>
                        </div>
                        <div className="pr-r-ea-col-t">
                            <h3 id="research-header">"Millennials and Gen Z dominate the market"</h3>
                            <p>Forbes Advisor suggests that  <b>78% of Americans opt for digital banking</b> (Underwood & Aldrich, 2023), especially for major working ages like millennials and Gen Z, a strong number over 98% (White, 2024).</p>
                            {/* <p>The same study also indicates <b>checking balance to be the most used feature across all generations</b>, while transferring money, budgeting, and chatting with the banks are significantly more active for Gen Z and Millennials (White, 2024).</p> */}
                        </div>
                    </div>
                    <div className="pr-r-ea-col">
                        <div className="pr-r-ea-col-img">
                            <img src={cos_rs_icon2} alt="" id="rs-icon"/>
                        </div>
                        <div className="pr-r-ea-col-t">
                            <h3 id="research-header">"Ethics and accessbility are top concerns"</h3>
                            <p style={{marginTop:'8.5px'}}>Users regarding privacy as important, more than a half <b>do not trust the service provider’s ethics</b> on their data selling policies (Lake & Foreman, 2021). Following by accessiblity and AI trends (Kreger, 2020;McKinsey & Company, 2023)</p>
                        </div>
                    </div>
                    <div className="pr-r-ea-col">
                        <div className="pr-r-ea-col-img">
                            <img src={cos_rs_icon3} alt="" id="rs-icon"/>
                        </div>
                        <div className="pr-r-ea-col-t">
                            <h3 id="research-header">"AI can provide highly anticipated assistantship"</h3>
                            <p>AI can help respond well in the form of <b>generative conversation to inform users for communication purposes</b> (Abbott, 2024). Also, it can help with blockages that prevent banks from earning more than they could (Abbott, 2024).</p>
                        </div>
                    </div>
                </div>
                <details style={{marginBottom:'120px'}}>
                  <summary>References</summary>
                  <p style={{margin:'5px 0px 5px 0', fontSize:'12px', width:'800px'}} >
                  • Gravier, E. (2024, January 24). Best Banks And Credit Unions For Mobile Banking of 2024. CNBC. Retrieved January 30, 2024, from https://www.cnbc.com/select/best-banks-credit-unions-for-mobile-banking
                  </p>
                  <p style={{margin:'5px 0px 5px 0', fontSize:'12px', width:'800px'}} >
                  • Underwood, J., & Aldrich, E. (2023, December 13). Consumer Banking Trends and Statistics 2024. Forbes. Retrieved January 30, 2024, from https://www.forbes.com/advisor/banking/banking-trends-and-statistics
                  </p>
                  <p style={{margin:'5px 0px 5px 0', fontSize:'12px', width:'800px'}} >
                  • White, A. (2024, January 2). Why Millennials, Gen Z Are Likely to Use Mobile Banking Apps. CNBC. Retrieved January 30, 2024, from https://www.cnbc.com/select/why-millennials-gen-z-use-mobile-banking-apps                  
                  </p>
                  <p style={{margin:'5px 0px 5px 0', fontSize:'12px', width:'800px'}} >
                  • McKinsey & Company. (2023, October 10). McKinsey's Global Banking Annual Review 2023. McKinsey. Retrieved January 30, 2024, from https://www.mckinsey.com/industries/financial-services/our-insights/global-banking-annual-review                  
                  </p>
                  <p style={{margin:'5px 0px 5px 0', fontSize:'12px', width:'800px'}} >
                  • Lake, R., & Foreman, D. (2021, April 5). Increase In Digital Banking Raises Consumer Data Privacy Concerns: How To Protect Yourself. Forbes. Retrieved February 2, 2024, from https://www.forbes.com/advisor/banking/digital-banking-consumer-data-privacy-concerns                  
                  </p>
                  <p style={{margin:'5px 0px 5px 0', fontSize:'12px', width:'800px'}} >
                  • Kreger, A. (2020, May 15). YouTube: Home. Retrieved February 2, 2024, from https://www.forbes.com/sites/forbesbusinesscouncil/2023/11/14/digital-banking-design-how-to-take-an-ecosystem-driven-approach/?sh=517add7ab2c1                   
                  </p>
                  <p style={{margin:'5px 0px 5px 0', fontSize:'12px', width:'800px'}} >
                  • Abbott, M. (2024, January 16). Forbes. Retrieved February 2, 2024, from https://www.forbes.com/sites/michaelabbott/2024/01/16/the-top-10-banking-trends-for-2024--the-age-of-ai/?sh=3d88b2924510 
                  </p>
                </details> 
            </div>
            <div className="pr-research-users-wrap" style={{paddingBottom:'120px'}}>
                <h2 id="title-h2" style={{textAlign:'center'}}>Users First</h2>
                <div className="pr-r-ea">
                    <div className="pr-r-ea-t">
   
                        <h3 id="research-header">"Data control and communication need fixing"</h3>

                        <p><b>Data and Connection Issues.</b> Most users rely on their data on banking mobile apps, which could be a problem when the internet is down.</p>
                        <p><b>Communication Issues.</b> The communication issues are either from users or the systems and database.</p>
                        <p><b>Lack help Issues</b> Users' problems do not constituent system's help center enough which can cause users' frustration and negativity.</p>
                    </div>
                    <div className="pr-r-ea-img" style={{padding:'0px'}}>
                        <img src={cos_sketch1} style={{width:'100%', borderRadius:'24px 24px 0 0'}} alt="" />
                        <img src={cos_sketch2} style={{width:'100%'}} alt="" />
                        <img src={cos_sketch3} style={{width:'100%', borderRadius:'0 0 24px 24px'}} alt="" />
                    </div>
                </div>
            </div>
            <div className="story" id="Testing-Wireframes" style={{backgroundColor:'#282c2f', paddingBottom:'120px'}}> 
            <div className="ux-r">
                <h2 id="title-h2" style={{textAlign:'center', marginBottom:'120px', marginTop:'0', color:'white'}}>Wireframes and Testing</h2>
                <div className="ux-research"> 
                <h3 style={{textAlign:'center', color:'white', marginBottom:'42px'}}>option A</h3>
                <ReserchBlockD 
                BlockIMG1={cos_arch1}
                BlockIMG2={cos_wireframe1}
                />
                <h3 style={{textAlign:'center', color:'white', marginBottom:'42px'}}>option B</h3>
                <ReserchBlockD 
                BlockIMG1={cos_arch2}
                BlockIMG2={cos_wireframe2}
                />
                <h3 style={{textAlign:'center', color:'white', marginBottom:'42px'}}>Version 5 Low-Fidelity</h3>
                <img src={cos_wireframes} style={{width:'100%'}} alt="" />
                </div>
            </div>
        </div>
        <div className="pd-scope-container" style={{marginTop:'0'}}>
                <div className="pd-im">
                    <h3 id="pd-im-st">Double</h3>
                    <p>Faster for users to read through the app in the new version.</p>
                </div>
                <div className="pd-im">
                    <h3 id="pd-im-st">90%</h3>
                    <p>More satisfied, especially for design systems consistency.</p>
                </div>
                <div className="pd-im">
                    <h3 id="pd-im-st">More</h3>
                    <p>Likelihood to adopt and retain using the product.</p>
                </div>
            </div>
        </div>
        {/* <div className="pr-mapping-container">
                <h2 id="title-h2">How about we get in touch?</h2>
                <div className="clickable-wrap">
                    <a className="clickable">Email Brann</a>
                    <a className="clickable">LinkedIn</a>
                </div>
        </div> */}
    </div>
    <div className="story" style={{padding:'80px 138px 40px 138px'}}> 
          <ProjectNavigator 
          BlockTitle1="Cos"
          BlockDetail1="FinTech "
          to1="/cos-finance"
          BlockTitle2="AmíGo"
          BlockDetail2="Travel "
          to2="/amigo"
          BlockTitle3="Racing Susan"
          BlockDetail3="FinTech "
          to3="/racing-susan"
          />
    </div>
    <Breadcrumb 
                top="project-top"
                section1="Introduction"
                section2="Product"
                section3="Prototype"
                section4="Research"
                section1Lable="Introduction"
                section2Lable="Product"
                section3Lable="Prototype"
                section4Lable="Research"
                currentPage="Cos"
                topScroll="460p"
            />
    <Footer />
    </div>
    <div className="mobile">
    <CardMobile 
          projectName="Cos Finance"
          projectImg={cos_banner}
          projectImgWidth="100%"
          projectImgBGColor="#0966FF"
          projectDescribtion="Helps late Millennials and Generation Z complete financial tasks fast and efficiently with a simple user flow and built-in assistance."
          bannerChip1="Product Design"
          bannerChip2="12 Weeks"
          bannerChip3="Lead Design"
          bannerChip4="Mobile | ATM"
          challenges="Complications and inconsistencies slow young professionals down from completing their financial tasks quickly. By leveraging design systems and thoughtful information architecture, we can help them achieve their goal efficiently and satisfactorily."
          roles="Identified users’ issues and needs, analyzed and brought solutions to designs, and developed prototypes for clear communication."
          tools="Figma | Google Suites | Miro | Adobe Illustrator | Adobe Photoshop | Sketchbook and Pen"
          bgIMG={cos_budget}
          bgColor=""
          compATitle=""
          compADetail=""
          textMargin="0 0 0 -50px"
          imgSet1={cos_set1}
          imgSet2={cos_set3}
          imgSet3={cos_set4}
          set1Head="Highlight key functions with customized widgets upfront."
          set1Detail="Save 300 milliseconds each time you want to complete a simple task."
          set2Head="Special features keep you on track with your goals."
          set2Detail="From our special AI Detector screening whatever you need to budgeting."
          set3Head="Keep you calm when there is a problem."
          set3Detail="Issues with our services? Worry not, our IT and CS team is here standing by for you."
          destination="https://www.figma.com/proto/241A3mUMzS2qWDJjhZ3xvx/COS?page-id=1%3A4&node-id=244-13433&node-type=FRAME&viewport=1032%2C771%2C0.68&t=qmMBLq6rKD2iBPEM-1&scaling=scale-down&content-scaling=fixed&starting-point-node-id=244%3A13433&show-proto-sidebar=1"
          bgcolor="rgb(0,106,255)"
          holder="Prototype"
        />
    </div>
    </>
  )
}

export default ProjectCos_New