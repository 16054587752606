import React, { useRef, useEffect } from 'react';
import {Link} from 'react-router-dom';
import '../css/Main.css';
import '../css/Elements.css'
import '../css/Projects.css';
import '../css/Research.css';
import Button from './Button';

function ProjectNavigator({BlockIMG1,BlockTitle1,BlockDetail1,to1,BlockIMG2,BlockTitle2,BlockDetail2,to2,BlockTitle3,BlockDetail3,to3, nav3Margin}) {

   
  return (
        <div className="project-navs">
            <Link to={to1} className="project-nav">
              <h3>{BlockTitle1}</h3>
              <p id="project-nav-link">{BlockDetail1}
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi   bi-arrow-up-right-circle-fill" viewBox="0 0 16 16">
                  <path d="M0 8a8 8 0 1 0 16 0A8 8 0 0 0 0 8m5.904 2.803a.5.5 0 1 1-.707-.707L9.293 6H6.525a.5.5 0 1 1 0-1H10.5a.5.5 0 0 1 .5.5v3.975a.5.5 0 0 1-1 0V6.707z"/>
               </svg>
              </p>
           </Link>
           <Link to={to2} className="project-nav">
              <h3>{BlockTitle2}</h3>
              <p id="project-nav-link">{BlockDetail2} 
               <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi   bi-arrow-up-right-circle-fill" viewBox="0 0 16 16">
                  <path d="M0 8a8 8 0 1 0 16 0A8 8 0 0 0 0 8m5.904 2.803a.5.5 0 1 1-.707-.707L9.293 6H6.525a.5.5 0 1 1 0-1H10.5a.5.5 0 0 1 .5.5v3.975a.5.5 0 0 1-1 0V6.707z"/>
               </svg>
              </p>
          </Link>
          <Link to={to3} className="project-nav">
              <h3>{BlockTitle3}</h3>
              <p id="project-nav-link">{BlockDetail3}
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi   bi-arrow-up-right-circle-fill" viewBox="0 0 16 16">
                  <path d="M0 8a8 8 0 1 0 16 0A8 8 0 0 0 0 8m5.904 2.803a.5.5 0 1 1-.707-.707L9.293 6H6.525a.5.5 0 1 1 0-1H10.5a.5.5 0 0 1 .5.5v3.975a.5.5 0 0 1-1 0V6.707z"/>
               </svg>
              </p>
           </Link>
        </div>
  )
}

export default ProjectNavigator