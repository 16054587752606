import React from 'react'
import {Link} from 'react-router-dom';
import "../css/Elements.css";

const Button = ({destination, linkto, holder, bgcolor}) => {
  return (
        <Link to={destination} href={linkto} className="button-standard" style={{backgroundColor:{bgcolor}}}>{holder}</Link>
  )
}

export default Button