import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { scrollThere } from './functions/scrollThere';
// CSS
import './css/Main.css'
import './css/Projects.css'
import './css/Research.css'
// COMPO
import HeroBanner from './elements/HeroBanner'
import Breadcrumb from './elements/Breadcrumb';
import ProjectBrief from './elements/ProjectBrief';
import ProjectComponentA from './elements/ProjectComponentA';
import ProjectComponentB from './elements/ProjectComponentB';
import ProjectComponentC from './elements/ProjectComponentC';
import Project from './elements/ProjectComponentC';
import ProjectComponentE from './elements/ProjectComponentE';
import ReserchBlockA from './elements/ReserchBlockA';
import ReserchBlockB from './elements/ReserchBlockB';
import ReserchBlockC from './elements/ReserchBlockC';
import ReserchBlockD from './elements/ReserchBlockD';
import Footer     from './elements/Footer';
import ProjectNavigator from './elements/ProjectNavigator';
import CardMobile from './elements/CardMobile';
// IMG
import cos_banner from './img/homepage/projectCos/cos_banner.png'
import cos_zelle from './img/homepage/projectCos/cos_zelle.png'
import cos_landing from './img/homepage/projectCos/cos_landing.png'
import cos_widget1 from './img/homepage/projectCos/cos_widget.png'
import cos_widget2 from './img/homepage/projectCos/cos_budget.png'
import cos_widget3 from './img/homepage/projectCos/cos_ai.png'
import cos_help from './img/homepage/projectCos/cos_help.png'
import cos_overall from './img/homepage/projectCos/cos_overall.png'
import cos_bigpicture from './img/homepage/projectCos/cos-bigpic-diagram.svg'
import cos_user_diagram1 from './img/homepage/projectCos/cos-users-op1.svg'
import cos_user_diagram2 from './img/homepage/projectCos/cos-users-op2.svg'
import cos_sketch1 from './img/homepage/projectCos/cos_scenario1.png'
import cos_sketch2 from './img/homepage/projectCos/cos_scenario2.png'
import cos_sketch3 from './img/homepage/projectCos/cos_scenario3.png'
import cos_journey from './img/homepage/projectCos/cos_userjourney.svg'
import cos_arch1 from './img/homepage/projectCos/cos-ux-dir1.svg'
import cos_arch2 from './img/homepage/projectCos/cos-ux-dir2.svg'
import cos_wireframe1 from './img/homepage/projectCos/cos-wireframes1.png'
import cos_wireframe2 from './img/homepage/projectCos/cos-wireframes2.png'
import cos_set1 from './img/homepage/projectCos/cos-set1.png'
import cos_set3 from './img/homepage/projectCos/cos-set3.png'
import cos_set4 from './img/homepage/projectCos/cos-set4.png'

function ProjectCos() {

    // linear-gradient(82deg, rgba(255,255,255,1) 24%, rgba(86,134,214,1) 63%)

  window.scrollTo(0,0);
  return (
    <>
    <div className="desktop">
      <div id="project-top">
        <HeroBanner 
        projectTitle="Cos Finance"
        projectHeadline="Helps late Millennials and Generation Z complete financial tasks fast and efficiently with a simple user flow and built-in assistance."
        bannerImage={cos_banner}
        borderR="50em 0 0 50em"
        bannerChip1="Product Design and Research"
        bannerChip2="12 Weeks"
        bannerChip3="Lead Design"
        bannerChip4="Mobile | ATM"
        bgColor="white"
        color="rgba(255,255,255,0.8)"
        tagBG="rgb(38, 92, 200)"
        tagCl="white"
        />
      </div>
      <Breadcrumb 
        top="project-top"
        section1="Introduction"
        section2="Product"
        section3="Prototype"
        section4="Research"
        section1Lable="Introduction"
        section2Lable="Product"
        section3Lable="Prototype"
        section4Lable="Research"
        currentPage="Cos"
      />
      <div className="stories">
        <div className="story" id="Introduction">
          <ProjectBrief 
          challenges="Complications and inconsistencies slow young professionals down from completing their financial tasks quickly. By leveraging design systems and thoughtful information architecture, we can help them achieve their goal efficiently and satisfactorily."
          roles="Identified users’ issues and needs, analyzed and brought solutions to designs, and developed prototypes for clear communication."
          tools="Figma | Google Suites | Miro | Adobe Illustrator | Adobe Photoshop | Sketchbook and Pen"
          />
        </div>
        <div className="story" id="Product">
          <ProjectComponentA 
            bgIMG={cos_zelle}
            bgColor="rgb(0,106,255)"
            compATitle="Just Three Clicks Away"
            compADetail="to finish your favorite tasks"
            blockATextMargin="auto 0 auto -60px"
          />
            <div className="cos-com-b">
                <ProjectComponentB 
                  logo={cos_widget1}
                  compBTitle="Widgets"
                  compBDetail="are customizable"
                  bgColor="rgb(237, 237, 237)"
                  textColor="#494949"
                  translate="translateX(-25%)"
                  align="center"
                  textMargin="auto auto auto 34px"
                />
                <ProjectComponentB 
                  logo={cos_widget2}
                  compBTitle="Budgeting"
                  compBDetail="will be easy"
                  bgColor="rgb(237, 237, 237)"
                  textColor="#494949"
                  align="center"
                  textMargin="auto auto auto 18px"
                />
                <ProjectComponentB 
                  logo={cos_widget3}
                  compBTitle="Capture"
                  compBDetail="quickly with AI"
                  bgColor="rgb(237, 237, 237)"
                  textColor="#494949"
                  translate="translateX(25%)"
                  align="center"
                  textMargin="auto auto auto 34px"
                />
            </div>
            <ProjectComponentC 
                logo={cos_help}
                compBTitle="No worries, even with problems"
                compBDetail="You can access IT and Customer Service for help at any time!"
                BGRoundColor="blue"
            />
        </div>
        <div className="story" id="Prototype">
            <ProjectComponentE
            figma="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2Fe0A5rsR2EDiLjcNUDC5oKK%2FUntitled%3Fpage-id%3D0%253A1%26node-id%3D410-4512%26viewport%3D-522%252C421%252C0.1%26t%3DKSl2H6OT2Bog4O4L-1%26scaling%3Dscale-down%26content-scaling%3Dfixed%26starting-point-node-id%3D410%253A4512%26show-proto-sidebar%3D1%26hide-ui%3D1"
            image={cos_overall}
            />
        </div>
        <div className="story" id="Research">
            <div className="v-text-container">
                <h2 id="proto-v-text" style={{color:'#999999', marginTop:'-80px'}}>UX Research</h2>
            </div>
            <div className="ux-research">
                <div className="ux-r-intro">
                    <p>This exploration includes background research, user behavior and preferences, existing product analysis, and design system analysis. The top concerns in many existing products are consistency, user flow, and product efficiency.</p>
                </div>
                <ReserchBlockA
                BlockTitle="Identify Opportunities"
                researchAIMG={cos_bigpicture}
                />
                <ReserchBlockB 
                BlockTitle1="Digital finance activities can be faster."
                BlockTitle2="An overloaded backend can interrupt flows."
                BlockTitle3="Identity verification is vital but slow."
                BlockDetail1="It could be much faster than ATMs and should be as convenient as swiping credit cards."
                BlockDetail2="An application that requires too frequent fetch requests can end up crashing the program."
                BlockDetail3="Human errors prevent seamless experiences, and technologies should assist not replace these issues."
                />
                <ReserchBlockC
                BlockIMG1={cos_user_diagram1}
                BlockTitle1="Rooms for Improvement"
                BlockTitle2="Regarding as Favored"
                BlockIMG2={cos_user_diagram2}
                BlockDetail1="From about 70 opinions, most issues are back-end issues, delays, and crashes for instance. While user flow, UI consistency, and readability are the top issues."
                BlockDetail2="Transfering is the top function to get users open to the apps, then checking balance and due dates on savings and credit card accounts."
                />
                <div className="cos-out-blockA">
                  <h2 style={{marginBottom:'80px', marginTip:'80px'}}>Markets, Users, and Products Analysis</h2>
                  <div className="cos-sketch-block">
                      <img src={cos_sketch1} alt="" />
                      <div className="sketch-block">
                        <h3>Data and Connection Issues</h3>
                        <p>Most users rely on their data on banking mobile apps, which could be a problem when the internet is down.</p>
                      </div>
                  </div>
                  <div className="cos-sketch-block" style={{marginTop:'60px'}}>
                      <img src={cos_sketch2} alt="" />
                      <div className="sketch-block" style={{marginTop:'-14px'}}>
                        <h3>Communication Issues</h3>
                        <p>The communication issues are either from users or the systems and database.</p>
                      </div>
                  </div>
                  <div className="cos-sketch-block">
                    <img src={cos_sketch3} alt="" />
                    <div className="sketch-block" style={{marginTop:'-14px'}}>
                      <h3>Lack help Issues</h3>
                      <p>Users' problems do not constituent system's help center enough which can cause users' frustration and negativity.</p>
                  </div>
            </div>
            <img src={cos_journey} style={{marginTop:'60px', marginBottom:'80px'}} alt="" />
                </div>
                <ReserchBlockB 
                BlockTitle1="Banking App Users"
                BlockTitle2="Finance Product Issues"
                BlockTitle3="AI and Banking App"
                BlockDetail1="Forbes Advisor suggests that 78% of Americans opt for digital banking (Underwood & Aldrich, 2023), especially for major working ages like millennials and Gen Z, a strong number over 98% (White, 2024). The same study also indicates checking balance to be the most used feature across all generations, while transferring money, budgeting, and chatting with the banks are significantly more active for Gen Z and Millennials (White, 2024). They also suggest that leveraging banking technology will be a way to help improve this growth profoundly (McKinsey & Company, 2023)."
                BlockDetail2="According to Forbs Adviser on KPMG study, despite a high number of users choosing to use online services and regarding privacy as important, more than a half do not trust the service provider’s ethics on their data selling policies (Lake & Foreman, 2021). As for accessibility, Forbes also suggests that it falls into the “user expectation” realm where competition on their experiences where they can access the product easily (Kreger, 2020). However, technologies also give rise to new ways to attract and help users better; one of those includes artificial intelligence as mentioned by McKinsey (McKinsey & Company, 2023)."
                BlockDetail3="As much as artificial intelligence has been mentioned lately in 2024, we can see how it could link the information gap between users and the banks; but what specifically we should look into improving and implementing them. From one of Forbes' reports, AI can help respond well in the form of generative conversation to inform users for communication purposes (Abbott, 2024). Not only that, it can likely help with touchpoint issues which were likely blockages that prevent banks from earning more than they could (Abbott, 2024)."
                />
                <details>
                  <summary>References</summary>
                  <p style={{margin:'5px 0px 5px 0', fontSize:'12px', width:'800px'}} >
                  • Gravier, E. (2024, January 24). Best Banks And Credit Unions For Mobile Banking of 2024. CNBC. Retrieved January 30, 2024, from https://www.cnbc.com/select/best-banks-credit-unions-for-mobile-banking
                  </p>
                  <p style={{margin:'5px 0px 5px 0', fontSize:'12px', width:'800px'}} >
                  • Underwood, J., & Aldrich, E. (2023, December 13). Consumer Banking Trends and Statistics 2024. Forbes. Retrieved January 30, 2024, from https://www.forbes.com/advisor/banking/banking-trends-and-statistics
                  </p>
                  <p style={{margin:'5px 0px 5px 0', fontSize:'12px', width:'800px'}} >
                  • White, A. (2024, January 2). Why Millennials, Gen Z Are Likely to Use Mobile Banking Apps. CNBC. Retrieved January 30, 2024, from https://www.cnbc.com/select/why-millennials-gen-z-use-mobile-banking-apps                  
                  </p>
                  <p style={{margin:'5px 0px 5px 0', fontSize:'12px', width:'800px'}} >
                  • McKinsey & Company. (2023, October 10). McKinsey's Global Banking Annual Review 2023. McKinsey. Retrieved January 30, 2024, from https://www.mckinsey.com/industries/financial-services/our-insights/global-banking-annual-review                  
                  </p>
                  <p style={{margin:'5px 0px 5px 0', fontSize:'12px', width:'800px'}} >
                  • Lake, R., & Foreman, D. (2021, April 5). Increase In Digital Banking Raises Consumer Data Privacy Concerns: How To Protect Yourself. Forbes. Retrieved February 2, 2024, from https://www.forbes.com/advisor/banking/digital-banking-consumer-data-privacy-concerns                  
                  </p>
                  <p style={{margin:'5px 0px 5px 0', fontSize:'12px', width:'800px'}} >
                  • Kreger, A. (2020, May 15). YouTube: Home. Retrieved February 2, 2024, from https://www.forbes.com/sites/forbesbusinesscouncil/2023/11/14/digital-banking-design-how-to-take-an-ecosystem-driven-approach/?sh=517add7ab2c1                   
                  </p>
                  <p style={{margin:'5px 0px 5px 0', fontSize:'12px', width:'800px'}} >
                  • Abbott, M. (2024, January 16). Forbes. Retrieved February 2, 2024, from https://www.forbes.com/sites/michaelabbott/2024/01/16/the-top-10-banking-trends-for-2024--the-age-of-ai/?sh=3d88b2924510 
                  </p>
                </details> 
                <div className="amigo-finding" style={{margin:'80px auto auto -5px'}}>
                  <h4>Findings</h4>
                  <div id="amigo-finding-no">
                    <h4 style={{backgroundColor:"#1365c3"}}>1</h4>
                    <p>Checking balances and transferring money remains the top priority for users when using finance products.</p>
                  </div>
                  <div id="amigo-finding-no">
                    <h4 style={{backgroundColor:"#1365c3"}}>2</h4>
                    <p>Product stability is the largest concern, if occurred too often, the service could lose customers. UX design can help create simple and efficient product systems.</p>
                  </div>
                  <div id="amigo-finding-no">
                    <h4 style={{backgroundColor:"#1365c3"}}>3</h4>
                    <p>Customer services are important to users, especially when help is needed. Product design should consider how to integrate functions that truly help users troubleshoot and fix issues quickly.</p>
                  </div>
                </div>
            </div>
        </div>
        <div className="story" id="Testing-Wireframes" style={{color:"white"}}> 
            <div className="v-text-container">
              <h2 id="proto-v-text" style={{color:'#999999', marginTop:'-80px'}}>Testing</h2>
            </div>
            <div className="ux-research"> 
            <ReserchBlockD 
            BlockIMG1={cos_arch1}
            BlockTitle1="Cos Information Architecture Option 1"
            BlockIMG2={cos_wireframe1}
            BlockTitle2="Cos Wireframe Option 1"
            />
            <ReserchBlockD 
            BlockIMG1={cos_arch2}
            BlockTitle1="Cos Information Architecture Option 2"
            BlockIMG2={cos_wireframe2}
            BlockTitle2="Cos Wireframe Option 2"
            />
            </div>
        </div>
        <div className="story" style={{padding:'80px 138px 40px 138px'}}> 
          <ProjectNavigator 
          BlockTitle1="Cos"
          BlockDetail1="FinTech "
          to1="/cos-finance"
          BlockTitle2="AmíGo"
          BlockDetail2="Travel "
          to2="/amigo"
          BlockTitle3="Racing Susan"
          BlockDetail3="FinTech "
          to3="/racing-susan"
          />
          <Footer />
        </div>
      </div>
    </div>
    <div className="mobile">
        <CardMobile 
          projectName="Cos Finance"
          projectImg={cos_banner}
          projectImgWidth="80%"
          projectImgBGColor="rgb(0,106,255)"
          projectDescribtion="Helps late Millennials and Generation Z complete financial tasks fast and efficiently with a simple user flow and built-in assistance."
          bannerChip1="Product Design"
          bannerChip2="12 Weeks"
          bannerChip3="Lead Design"
          bannerChip4="Mobile | ATM"
          challenges="Complications and inconsistencies slow young professionals down from completing their financial tasks quickly. By leveraging design systems and thoughtful information architecture, we can help them achieve their goal efficiently and satisfactorily."
          roles="Identified users’ issues and needs, analyzed and brought solutions to designs, and developed prototypes for clear communication."
          tools="Figma | Google Suites | Miro | Adobe Illustrator | Adobe Photoshop | Sketchbook and Pen"
          bgIMG={cos_zelle}
          bgColor="rgb(0,106,255)"
          compATitle="Just Three Clicks Away"
          compADetail="to finish your favorite tasks"
          textMargin="0 0 0 -50px"
          imgSet1={cos_set1}
          imgSet2={cos_set3}
          imgSet3={cos_set4}
          set1Head="Highlight key functions with customized widgets upfront."
          set1Detail="Save 300 milliseconds each time you want to complete a simple task."
          set2Head="Special features keep you on track with your goals."
          set2Detail="From our special AI Detector screening whatever you need to budgeting."
          set3Head="Keep you calm when there is a problem."
          set3Detail="Issues with our services? Worry not, our IT and CS team is here standing by for you."
          destination="https://www.figma.com/proto/e0A5rsR2EDiLjcNUDC5oKK/Untitled?page-id=0%3A1&node-id=410-4512&viewport=-4007%2C1511%2C0.4&t=PVHQNfcbcrBxHiQf-1&scaling=scale-down&content-scaling=fixed&starting-point-node-id=410%3A4512&show-proto-sidebar=1"
          bgcolor="rgb(0,106,255)"
          holder="Prototype"
        />
    </div>
    </>
  )
}

export default ProjectCos