import React      from 'react'
import {Link}     from 'react-router-dom';

import './css/Main.css'
import "./css/Home.css";

import CardHome   from './elements/CardHome';
import Footer     from './elements/Footer';
import Navbar     from './elements/Navbar';
// IMAGE
import hp_avartar from './img/homepage/hp_brann.png'
import hp_cloud   from './img/homepage/hp_cloud.svg'
import hp_cos     from './img/homepage/hp_cos.png'
import hp_amigo   from './img/homepage/hp_amigo.png'
import hp_rs      from './img/homepage/hp_racingSusan.png'
import hp_kitchr  from './img/homepage/hp_kitchr.png'
import hp_foodfight from './img/homepage/hp_foodfight.png'
import hp_library from './img/homepage/hp_library.png'
import hp_branding from './img/homepage/hp_branding.png'
import Cookie from './elements/Cookie';


function Homepage() {
  window.scrollTo(0,0);
  return (
    <>
    {/* <Cookie /> */}
    <Navbar />
    <div className="top-banner-container">
      <div className="mb-img">
        <img src={hp_avartar} alt="brann_avartar" id="brann_avartar_mb"/>
      </div>
      <div className="banner-container">
        <div className="text-wrapper" style={{marginTop:'-20px'}}>
          <h3 id="landing_intro">Hello! This is Brann, I'm a</h3>
          <h1 id="landing_title">PRODUCT DESIGNER.</h1>
          <h3 id="landing_intro">I design, code, prototype, illustrate, and manage for products' efficiency and user-friendliness!</h3>
          <div className="tags" style={{margin:'-20px 0 0 -20px'}}>
            <div className="tag">Finance</div>
            <div className="tag">Commercial</div>
            <div className="tag">Social Media</div>
          </div>
          <div className="mobile">
            <div className="mb-tag">
              <div className="tag">Finance</div>
              <div className="tag">Commercial</div>
              <div className="tag">Social Media</div>
            </div>
          </div>
        </div>
        <img src={hp_cloud} alt="cloud" id="cloud"/> 
        <div className="banner-avartar">
          <img src={hp_avartar} alt="brann_avartar" id="brann_avartar"/>
        </div>
      </div>  
    </div>
    <div className="home-cards-container">
      <div className="cards-container">
        <CardHome
          destination="/cos-finance"
          image={hp_cos}
          imgWidth="100%"
          imgMargin="-30px auto auto auto"
          bgTitle="COS"
          bgColor="#0966FF"
          title="Cos"
          describtion1="• Get financial tasks done quicker for young professionals"
          describtion2="Shortcut saving 40% of your time managing your money" 
          chip1="HiFi" 
          chip2="Design" 
          chip3="Testing"
          chipColor="black"
          topColor="white"
          bgFontColor=""
        />
        <CardHome
          destination="/amigo"
          image={hp_amigo}
          imgWidth="53%"
          imgMargin="-10px auto auto auto"
          bgTitle="AMIGO"
          bgColor="#E3FF0A"
          title="AmíGo"
          describtion1="• Manage your trip seamlessly"
          describtion2="Seamlessly connecting you, people, and data you need!" 
          chip1="HiFi" 
          chip2="Interface" 
          chip3="Interviews"
          chipColor="black"
          bgFontColor=""
        />
        <CardHome
          destination="/racing-susan"
          image={hp_rs}
          bgTitle="RACING"
          bgColor="#FF2654"
          title="Racing Susan"
          describtion1="• Finance companion above your messy tables"
          describtion2="Assisting small businesses to manage better finances" 
          chip1="SaaS" 
          chip2="B2B" 
          chip3="Fintech" 
          chipColor="black"
          topColor="white"
        />
        <CardHome
          destination="/kitchr"
          image={hp_kitchr}
          bgTitle="KITCHR"
          bgColor="#FFD73F"
          title="Kithcr"
          describtion1="• Local Groceries"
          describtion2="Linking food community" 
          chip1="Wireframe" 
          chip2="Sketch" 
          chipColor="black"
        />
        <CardHome
          destination="/foodfight"
          image={hp_foodfight}
          bgTitle="FOODFIGHT"
          bgColor="#FFD73F"
          title="FoodFight"
          describtion1="• Intern Project"
          describtion2="Linking food and games" 
          chip1="Prototype" 
          chip2="Teams" 
          chipColor="black"
        />
        <CardHome
          destination="/library"
          image={hp_library}
          bgTitle="LIB"
          bgColor="#FFD73F"
          title="Library"
          describtion1="• Front-End Data"
          describtion2="Smoother Search!" 
          chip1="3D Model" 
          chip2="Ideation" 
          chipColor="black"
        />
        <CardHome
          destination="/hanover"
          image={hp_branding}
          bgTitle="BRAND"
          bgColor="#FFD73F"
          title="Hanover"
          describtion1="• Design System"
          describtion2="Design System" 
          chip1="Components" 
          chip2="Library" 
          chipColor="black"
        />
      </div>
      <div className="story" style={{marginBottom:'-80px'}}> 
          <Footer />
        </div>
    </div>
    </>
  )
}

export default Homepage