import React from 'react'
import { useState, useEffect } from 'react';
import "../css/Elements.css";
import Button from './Button';

function Footer() {

    function Clock(){
        const [time,setTime] = useState(new Date().toLocaleDateString());
        useEffect(() => {
            const intv = setInterval(() =>{
                setTime(new Date().toLocaleDateString());
            }, 1000);
            return() => clearInterval(intv);      
        }, []);
        return(
            <div>
                <p>{time}</p>
            </div>
        )
    }
      
  return (
    <footer>
      <div className="footer-container-mb">
        <div className="ft-l">
            <p>E Palo Alto, CA</p>
            <div className="line"></div>
            <p>Passionately crafted with React framework.</p>
        </div>
        <div className="ft-m">
            <p>Bannawit Khattiyanont © 2024</p>
        </div>
        <div className="ft-r">
        <p style={{marginRight:'10px'}}><Clock /></p>
        <div className="connect">
            <Button 
            destination="mailto:bannawit@umich.edu?subject=We're interested in your work&body=Hey Brann,%0A%0A We are interested in your project and would like to schedule a meeting, when is your availability? %0A%0A Best"
            linkto=""
            holder="Email Brann"
            bgcolor="#494949"/>
            <Button 
            destination="https://www.linkedin.com/in/bannawit-brann-khattiyanont/"
            linkto=""
            holder="LinkedIn"
            bgcolor="#494949"/>
        </div>
        </div>
      </div>
      <div className="footer-desktop">
      <div className="ft-l">
            <p>Passionately crafted with React framework.</p>
            <div className="line"></div>
            <p>Palo Alto, CA</p>
        </div>
        <div className="ft-m">
            <p>Bannawit Khattiyanont © 2024</p>
        </div>
        <div className="ft-r">
        <p style={{marginRight:'10px'}}><Clock /></p>
        <div className="connect">
            <Button 
            destination="mailto:bannawit@umich.edu?subject=We're interested in your work&body=Hey Brann,%0A%0A We are interested in your project and would like to schedule a meeting, when is your availability? %0A%0A Best"
            linkto=""
            holder="Email Brann"
            bgcolor="#494949"/>
            <Button 
            destination="https://www.linkedin.com/in/bannawit-brann-khattiyanont/"
            linkto=""
            holder="LinkedIn"
            bgcolor="#494949"/>
        </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer