import React from 'react'
import {Link} from 'react-router-dom';
import "../css/Mobile.css";
import "../css/Elements.css";
import "../css/Home.css";
import Navbar from './Navbar';
import ProjectBrief from './ProjectBrief';
import ProjectComponentA from './ProjectComponentA';
import Footer from './Footer';
import Button from './Button';

const CardHome = ({
  projectName, 
  projectImg, 
  projectImgWidth,
  projectImgBGColor, 
  projectDescribtion,
  tagBGColor,
  tagTextColor,
  bannerChip1,
  bannerChip2,
  bannerChip3,
  bannerChip4,
  challenges,
  roles,
  tools,
  bgIMG,
  bgColor,
  compATitle,
  compADetail,
  textMargin,
  imgSet1,
  imgSet2,
  imgSet3,
  imgSet4,
  set1Head,
  set2Head,
  set3Head,
  set4Head,
  set1Detail,
  set2Detail,
  set3Detail,
  destination,
  bgcolor,
  holder,
  blockATextColor,
  blockATextMargin,
  FigmaOrNo
  }) => {
  return (
    <>
    <div className="mobile-container">
      <Navbar />
      <div className="mb-header">
        <img src={projectImg} style={{width:projectImgWidth}} id="mb-banner" alt="" />
        <div className="mb-project-bg" style={{background:projectImgBGColor}}></div>
      </div>
      <h1 id="mobile-header-title">{projectName}</h1>
      <p>{projectDescribtion}</p>
      <div className="bannerTags" style={{justifyContent:'start', gap:'12px', marginTop:'40px'}}>
          <div className="bannerTag" style={{backgroundColor:tagBGColor, color:tagTextColor}}>{bannerChip1}</div>
          <div className="bannerTag" style={{backgroundColor:tagBGColor, color:tagTextColor}}>{bannerChip2}</div>
          <div className="bannerTag" style={{backgroundColor:tagBGColor, color:tagTextColor}}>{bannerChip3}</div>
          <div className="bannerTag" style={{backgroundColor:tagBGColor, color:tagTextColor}}>{bannerChip4}</div>
      </div>
      <ProjectBrief 
          challenges={challenges}
          roles={roles}
          tools={tools}
          />
      <div className="mid-banner" style={{marginTop:'-80px', marginBottom:'40px'}}>
        <ProjectComponentA 
              bgIMG={bgIMG}
              bgColor={bgColor}
              compATitle={compATitle}
              compADetail={compADetail}
              textMargin={textMargin}
              blockATextColor={blockATextColor}
              blockATextMargin={blockATextMargin}
        />
      </div>
      <div className="mockup-sets">
        <img src={imgSet1} id="project-mockup" alt="" />
        <h3>{set1Head}</h3>
        <p>{set1Detail}</p>
      </div>
      <div className="mockup-sets">
        <img src={imgSet2} id="project-mockup" alt="" />
        <h3>{set2Head}</h3>
        <p>{set2Detail}</p>
      </div>
      <div className="mockup-sets">
        <img src={imgSet3} id="project-mockup" alt="" />
        <h3>{set3Head}</h3>
        <p>{set3Detail}</p>
      </div>
      <div className="mockup-sets">
        <h3 style={{display:FigmaOrNo}}>Available on Figma</h3>
        <Button 
        destination={destination}
        bgcolor={bgcolor}
        holder={holder}
        />
      </div>
      <Footer />
    </div>
    </>
  )
}

export default CardHome