import React, { useRef, useEffect } from 'react';
import '../css/Main.css';
import '../css/Elements.css'
import '../css/Projects.css';
import '../css/Research.css';
import Button from './Button';

function ReserchBlockB({BlockTitle1,BlockDetail1,BlockTitle2,BlockDetail2,BlockTitle3,BlockDetail3}) {

   
  return (
        <div className="ResearchB">
          <div className="blockB-1">
            <h3>{BlockTitle1}</h3>
            <p>{BlockDetail1}</p>
          </div>
          <div className="blockB-1">
            <h3>{BlockTitle2}</h3>
            <p>{BlockDetail2}</p>
          </div>
          <div className="blockB-1">
            <h3>{BlockTitle3}</h3>
            <p>{BlockDetail3}</p>
          </div>
        </div>
  )
}

export default ReserchBlockB