import React from 'react'
import {Link} from 'react-router-dom';
import "../css/Elements.css";
import "../css/Home.css";

const CardHome = ({destination, title, bgTitle, bgFontColor, bgColor, chipColor, topColor, image, imgWidth, imgMargin, describtion1, describtion2, chip1, chip2, chip3}) => {
  return (
    // <div className="card-container">
    //     <Link to={destination} className="card-wraper">
    //         <div className="card-title">
    //             <p style={{color:topColor}}><b id="special-title">{title}</b> {describtion1}</p>
    //             <svg xmlns="http://www.w3.org/2000/svg" id="arrow" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
    //                 <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"/>
    //             </svg>
    //         </div>
    //         <div className="img-container">
    //             <img src={image} alt="project-banner"  id="project-thumbnail" />
    //         </div>
    //         {/* <div className="card-slide-in">
    //             <h3 style={{fontSize:'28px'}} id="special-title">{title} 
    //             <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi   bi-arrow-up-right-circle-fill" viewBox="0 0 16 16">
    //               <path d="M0 8a8 8 0 1 0 16 0A8 8 0 0 0 0 8m5.904 2.803a.5.5 0 1 1-.707-.707L9.293 6H6.525a.5.5 0 1 1 0-1H10.5a.5.5 0 0 1 .5.5v3.975a.5.5 0 0 1-1 0V6.707z"/>
    //             </svg>
    //             </h3>
    //             <p style={{fontSize:'15px'}}>{describtion2}</p>
    //             <div className="chips" style={{marginBottom:'6px'}}>
    //                 <p style={{fontSize:'12px'}} id="chip">{chip1}</p>
    //                 <p style={{fontSize:'12px'}} id="chip">{chip2}</p>
    //                 <p style={{fontSize:'12px'}} id="chip">{chip3}</p>
    //             </div>
    //         </div> */}
    //         <h2 id="bgText" style={{fontSize:bgFontSize, color:bgTextColor}}>{title2}</h2>
    //         <div className="card-p-detail">
    //             <h3 style={{fontSize:'28px'}} id="special-title">{title} 
    //                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi   bi-arrow-up-right-circle-fill" viewBox="0 0 16 16">
    //                 <path d="M0 8a8 8 0 1 0 16 0A8 8 0 0 0 0 8m5.904 2.803a.5.5 0 1 1-.707-.707L9.293 6H6.525a.5.5 0 1 1 0-1H10.5a.5.5 0 0 1 .5.5v3.975a.5.5 0 0 1-1 0V6.707z"/>
    //                 </svg>
    //             </h3>
    //             <p style={{fontSize:'15px', lineHeight:'20px', marginTop:'-20px'}}>{describtion2}</p>
    //             <div className="chips" style={{marginBottom:'6px'}}>
    //                 <p style={{fontSize:'12px'}} id="chip">{chip1}</p>
    //                 <p style={{fontSize:'12px'}} id="chip">{chip2}</p>
    //                 <p style={{fontSize:'12px'}} id="chip">{chip3}</p>
    //             </div>
    //         </div>
    //     </Link>
    // </div>
    <Link to={destination} className="home-card-container">
        <p className="before-text" style={{color:topColor}}><b id="special-title" style={{fontSize:'17px'}}>{title}</b> {describtion1} 
        </p>
        <div className="arrow-wraper">
          <svg id="arrow1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi    bi-arrow-up-right-circle-fill" viewBox="0 0 16 16">
          <path d="M0 8a8 8 0 1 0 16 0A8 8 0 0 0 0 8m5.904 2.803a.5.5 0 1 1-.707-.707L9.293 6H6.525a.5.5 0 1 1 0-1H10.5a.5.5 0 0 1 .5.5v3.975a.5.5 0 0 1-1 0V6.707z"/>
          </svg>
        </div>
        <div className="home-card-wraper">
            <div className="h-c-img">
                <img src={image} alt="" style={{width:imgWidth, margin:imgMargin, textAlign:'center'}} id="h-c-thumbnail" />
            </div>
            <h2 className="background-text" style={{color:bgFontColor}}>{bgTitle} 
            </h2>
        </div>
        <div className="animate-object">
          <div className="mobile" style={{color:'white', padding:'20px', textAlign:'left'}}>
            <h2 style={{marginTop:'-5px'}}>{title}</h2>
            <p style={{fontSize:'14px', lineHeight:'20px', marginTop:'-10px'}}>{describtion2}</p>
          </div>
          <div className="p-title-wraper">
            <div className="title-wrapper">
                <h2 id="animate-title">{title}</h2>
            </div>
            <div className="grouping">
                <p style={{fontSize:'15px'}} id="animate-des">{describtion2}</p>
                <div className="chips" id="chip-bigguy" style={{marginBottom:'6px', color:chipColor}}>
                  <p style={{fontSize:'12px'}} id="chip">{chip1}</p>
                  <p style={{fontSize:'12px'}} id="chip">{chip2}</p>
                  <p style={{fontSize:'12px'}} id="chip">{chip3}</p>
                </div>
                <div className="chips" id="chip-smallguy"style={{marginBottom:'6px', color:chipColor}}>
                  <p style={{fontSize:'12px'}} id="chip">{chip1}</p>
                  <p style={{fontSize:'12px'}} id="chip">{chip2}</p>
                </div>
            </div>
          </div>
        </div>
        <div className="background" style={{background:bgColor}}>
        </div>
    </Link>
  )
}

export default CardHome