import React, { useRef, useEffect } from 'react';
import '../css/Main.css';
import '../css/Elements.css'
import '../css/Projects.css';
import '../css/Research.css';
import Button from './Button';

function ReserchBlockA({BlockTitle,researchAIMG, resAWidth, imgPadding, imgMargin, BGColor,BlockADetail}) {

   
  return (
        <div className="ResearchA">
          <h2>{BlockTitle}</h2>
          <p style={{width:'60%'}}>{BlockADetail}</p>
          <img src={researchAIMG} style={{width:resAWidth, padding:imgPadding, background:BGColor, borderRadius:'0.8em', margin:imgMargin}} alt="" />
        </div>
  )
}

export default ReserchBlockA